export interface FiStep {
  code: string;
  name?: string;
  url?: string;
  state?: string;
  hasError?: boolean;
  active?: boolean;
  statusMessage?: string;
  label?: string;
}
