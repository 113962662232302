<div [attr.data-cy]="getUploaderLabel(true)">
  <label class="label" [innerHtml]="getUploaderLabel()"> </label>
  <ng-content></ng-content>

  <ng-container *ngIf="hasErrorAndTouched">
    <div class="error-text">{{ getRequiredErrorMessage }}</div>
  </ng-container>
  <ng-container *ngIf="documentCategory.value?.isRemoving && formGroup.controls[documentCategory.key].value">
    <div class="error-text">{{ deleteMessage }}</div>
  </ng-container>

  <ng-container *ngIf="!documentCategory.value?.isRemoving">
    <div class="file-upload-row-container" *ngIf="hasUploadedDocument && !uploadMore && multipleDocument">
      <button
        data-cy="addAdditionalDocument"
        goa-button
        buttonType="tertiary"
        (click)="onUploadMore()"
        [iesAutofocus]="documentInProgress?.completed"
      >
        + Add additional document
      </button>
    </div>
    <div class="file-upload-container" *ngIf="!hasUploadedDocument || uploadMore">
      <div class="document-type-dropdown">
        <div
          class="default-document-type"
          *ngIf="documentCategory.value.documentTypes.length <= 1 && !documentCategory.value.hideDocumentTypeLabel"
        >
          {{
            (documentCategory.value.documentTypes.length === 0 && documentCategory.value.displayValue) ||
              documentCategory.value.documentTypes[0]
          }}
        </div>

        <mat-select
          *ngIf="documentCategory.value.documentTypes.length > 1"
          role="combobox"
          [formControl]="documentTypeFormControl"
          data-cy="documentType"
          aria-label="Document Type"
          [attr.aria-label]="documentCategory.value.displayValue"
          [attr.disabled]="documentCategory.value.documentTypes.length === 0 ? 'disabled' : null"
          placeholder="{{ selectOneText }}"
        >
          <mat-option [attr.role]="textbox" disabled value="">{{ selectOneText }}</mat-option>
          <mat-option
            style="height: unset; white-space: normal"
            *ngFor="let type of documentCategory.value.documentTypes"
            [value]="type"
          >
            {{ type }}
          </mat-option>
        </mat-select>
        <div class="helper-text">{{ documentCategory.value.helpText }}</div>
      </div>
      <div *ngIf="documentTypeFormControl.value">
        <div class="file-upload-button-container">
          <label
            class="upload-button"
            [ngClass]="{ 'enabled-class-primary': isUploadEnabled, 'disabled-class': !isUploadEnabled }"
            data-cy="'uploadDocument'"
            role="button"
            aria-label="+Upload Document"
            [attr.tabindex]="isUploadEnabled ? 0 : null"
            (keyup.enter)="$event.target.click()"
            (keyup.space)="$event.target.click()"
          >
            <input
              id="{{ uploadButtonId }}"
              *ngIf="isUploadEnabled"
              type="file"
              (change)="onFilesSelected($event.target.files)"
              [accept]="_accept"
              [multiple]="false"
            />
            + Upload document
          </label>
          <div class="file-uploading">
            <div *ngIf="!errors && documentInProgress?.progress">{{ documentInProgress.name }}</div>
            <div class="progress-bar" *ngIf="!errors && documentInProgress?.progress">
              <div class="progress" [style.width]="documentInProgress.progress + '%'"></div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="errors?.maxFileSize">
          <div class="error-text">
            Unsupported file size. Please try again with file size under
            {{ fileService.formatBytes(errors.maxFileSize) }}
            and greater than 0 bytes.
          </div>
        </ng-container>
        <ng-container *ngIf="errors?.fileExtension">
          <div class="error-text">Unsupported file format. Please try again with below mentioned formats.</div>
        </ng-container>
        <ng-container *ngIf="errors?.mimeType">
          <div class="error-text">Unsupported file mime type. Please try again with below mentioned formats.</div>
        </ng-container>
        <ng-container *ngIf="errors?.fileLoad">
          <div class="error-text">{{ errors?.fileLoad }}</div>
        </ng-container>

        <div class="helper-text">({{ acceptDisplay }})</div>
      </div>
    </div>
    <div data-cy="optOut" class="optout" *ngIf="optOutLabel && (initOptOut || initWithError || alwaysShowOptOut)">
      <mat-checkbox
        [attr.data-cy]="getDataCy(dataCy, documentCategory.key, 'optOut')"
        [disabled]="optOutDisabled(documentCategory.key)"
        [checked]="getOptOutChecked(documentCategory.key)"
        (change)="onOptOutChanged($event, documentCategory.key)"
        ><span data-cy="optOutLabel">{{ optOutLabel }}</span></mat-checkbox
      >
    </div>
  </ng-container>
</div>
