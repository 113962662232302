import { RowInputType } from './row-input-type';

export interface UploadLookup {
  code?: string;
  displayValue: string;
  order?: number;
  documentTypes: string[];
  helpText?: string;
  isRemoving?: boolean;
  needSupportDocument?: boolean;
  isOptional?: boolean;
  suppressOptionalLabel?: boolean;
  displayCategory?: string;
  optOutLabel?: string;
  optOutLabelTransform?: string; //this is needed to dynamically transform the label based on spouseLabel (or any other dynamic place holders).
  requiredErrorOverride?: string;
  requiredErrorOverrideTransform?: string;
  categoryTitleOverride?: string;
  hideDocumentTypeLabel?: boolean; //only applies if there is only 1 document type, otherwise it shows the select box (not label).
  inputType?: RowInputType;
}
