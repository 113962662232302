import { Component, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhoneNumber } from '@common/entities';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Form component for entry of multiple phone numbers
 */
@Component({
  selector: 'common-phone-number-multiple',
  templateUrl: './phone-number-multiple.component.html',
  styleUrls: ['./phone-number-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberMultipleComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberMultipleComponent,
      multi: true,
    },
  ],
})
export class PhoneNumberMultipleComponent implements ControlValueAccessor, OnDestroy {
  _destroy$ = new Subject<void>();

  /**
   * Label of the phone number.  Defaults to ''
   */
  @Input() label = 'Phone Numbers';
  /**
   * Should the label show
   */
  @Input() showLabel = false;

  /**
   * Should the control allow zero numbers
   */
  @Input() allowNoNumbers = false;

  @Input() isRequired = true;

  @Input() validateAreaCode = false;
  @Input() dataCyPrefix = '';

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    if (value) {
      this.formArray.markAllAsTouched();
    } else {
      this.formArray.markAsUntouched();
    }
  }

  formArray = this.formBuilder.array([]);
  componentDisabled = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    return !this.hasInvalidOrEmptyPhoneNumber() ? null : { invalid: true };
  }

  writeValue(obj: PhoneNumber[]): void {
    const formArrayLength = this.formArray.length;
    const phoneArrayLength = obj.length;

    if (phoneArrayLength > formArrayLength) {
      for (let i = phoneArrayLength - 1; i > formArrayLength - 1; i--) {
        this.formArray.push(this.formBuilder.control({ phoneNumber: obj[i].phoneNumber, type: obj[i].type }), {
          emitEvent: false,
        });
      }
    } else if (phoneArrayLength < formArrayLength) {
      for (let i = formArrayLength - 1; i > phoneArrayLength - 1; i--) {
        this.formArray.removeAt(i, { emitEvent: false });
      }
    }
    this.formArray.setValue(
      obj.map((phone) => {
        return { phoneNumber: phone.phoneNumber, type: phone.type };
      }),
      { emitEvent: false }
    );

    if (this.formArray.length === 0 && !this.allowNoNumbers) {
      this.formArray.push(
        this.formBuilder.control({
          phoneNumber: '',
          type: 'Mobile',
        }),
        { emitEvent: false }
      );
    }

    if (this.formArray.touched) {
      this.formArray.markAllAsTouched();
    }
  }

  registerOnChange(fn) {
    this.formArray.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(fn);
  }
  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formArray.disable({ emitEvent: false });
      this.componentDisabled = true;
    } else {
      this.formArray.enable({ emitEvent: false });
      this.componentDisabled = false;
    }
  }

  addNumber() {
    this.formArray.push(
      this.formBuilder.control({
        phoneNumber: '',
        type: 'Mobile',
      })
    );

    if (this.formArray.touched) {
      this.formArray.markAllAsTouched();
    }
  }

  hasInvalidOrEmptyPhoneNumber(): boolean {
    if (this.isRequired) return this.formArray.invalid || this.isAnyPhoneNumberEmpty();
    else return this.formArray.invalid || (this.isAnyPhoneNumberEmpty() && this.formArray.length > 0);
  }

  isAnyPhoneNumberEmpty(): boolean {
    return this.formArray.value.some((x) => x.phoneNumber === '');
  }
  /**
   * Called when the delete number button is clicked
   * @param $index The index of the number being deleted
   */
  deleteNumber(index) {
    if (!this.componentDisabled) {
      this.formArray.removeAt(index);
    }
  }
}
