export interface IntakeLabel {
  label: string;
  spousePartnerLabel?: string;
  abbreviationLabel?: string;
  abbreviationSpouseLabel?: string;
  alternateLabel?: string;
  alternateLabelSpouse?: string;
  replacementLabel?: string;
  helperText?: string;
  alternateHelperText?: string;
}

export interface IntakeLabelCollection {
  [name: string]: IntakeLabel;
}
