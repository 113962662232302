import { Office } from './office.entity';

export interface User {
  _id?: string;
  name?: string;
  roles?: string[];
  groups?: string[];
  primaryRole?: string;
  title?: string;
  location?: string;
  email?: string;
  phone?: string;
  office?: Office;
  ip?: string;
  district?: string;
  agency?: string;
  enabled?: boolean;
  serviceProviderId?: string;
  startDate?: Date;
}
