<div
  class="client-intake-form-control"
  [ngClass]="{ 'has-error': hasError }"
  [attr.aria-invalid]="hasError ? 'true' : 'false'"
>
  <label *ngIf="shouldUseLabel" [ngClass]="{ 'label-on-top': labelOnTop }" class="label">
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </label>
  <div
    *ngIf="!shouldUseLabel"
    [attr.aria-label]="label"
    role="group"
    [ngClass]="{ 'label-on-top': labelOnTop }"
    class="label"
  >
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </div>

  <ng-template #content>
    <span *ngIf="!hideLabel" class="span-label" [attr.aria-label]="label">{{ _label }}</span>
    <common-info-icon
      *ngIf="container && info"
      [containerElement]="container"
      [messageElement]="info"
    ></common-info-icon>
    <span class="optional-label" *ngIf="isOptional && !hideOptionalLabel">(Optional)</span>
    <common-info-icon-tooltip *ngIf="infoTooltip" [message]="infoTooltip"></common-info-icon-tooltip>
    <a class="external-link" *ngIf="externalLink" (click)="openExternalLink(externalLink)"
      >{{ externalLinkLabel }}<mat-icon class="external-link-icon" svgIcon="outgoing-link"></mat-icon>
    </a>
    <common-help-icon-tooltip
      *ngIf="helpTooltip"
      [helpTitle]="helpTitle"
      [helpTooltip]="helpTooltip"
    ></common-help-icon-tooltip>
    <div class="helper-text" *ngIf="helperText">
      {{ helperText }}
    </div>
    <!-- some content is requiring this as inline/inline-block-->
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="helper-message" *ngIf="helperMessage" [innerHtml]="helperMessage"></div>
  </ng-template>
  <div
    class="error-text"
    *ngIf="hasError && showErrorMessages"
    [attr.data-cy]="(cy ?? controlName ?? '') + '-error-message'"
  >
    <ng-container *ngIf="errors.customMessage"><div [innerHTML]="errors.customMessage"></div></ng-container>
    <ng-container *ngIf="errors.required">{{ requireFieldMessage ?? abbreviatedLabel + ' is required' }}.</ng-container>
    <ng-container
      *ngIf="errors.mask || errors.mod10Validation || errors.invalidPostalCode || errors.email || errors.pattern"
      >{{ abbreviatedLabel }} is invalid.</ng-container
    >
    <ng-container *ngIf="errors.legalName">{{ abbreviatedLabel }} cannot contain numbers or symbols.</ng-container>

    <ng-container *ngIf="!!errors.minlength">
      {{ abbreviatedLabel }}'s minimum length is {{ errors.minlength.requiredLength }}.
    </ng-container>
    <ng-container *ngIf="!!errors.maxlength">
      {{ abbreviatedLabel }}'s maximum length is {{ errors.maxlength.requiredLength }}.
    </ng-container>
    <ng-container *ngIf="errors.minDate">
      <ng-container *ngIf="errors.minDate.message">
        {{ errors.minDate.message }}
      </ng-container>
      <ng-container *ngIf="!errors.minDate.message">
        {{ abbreviatedLabel }} cannot be prior to {{ errors.minDate.label || (errors.minDate.min | date) }}.
      </ng-container>
    </ng-container>
    <ng-container *ngIf="errors.maxDate">
      <ng-container *ngIf="errors.maxDate.message">
        {{ errors.maxDate.message }}
      </ng-container>
      <ng-container *ngIf="!errors.maxDate.message">
        {{ abbreviatedLabel }} cannot be after {{ errors.maxDate.label || (errors.maxDate.max | date) }}.
      </ng-container>
    </ng-container>
    <ng-container *ngIf="errors.min">
      {{ abbreviatedLabel }} {{ errors.min.dataType === 'date' ? 'cannot be prior to ' : 'must over' }}
      {{
        errors.min.label ||
          (displayDollarSign ? '$' : '') + (errors.min.dataType === 'date' ? (errors.min.min | date) : errors.min.min)
      }}.
    </ng-container>
    <ng-container *ngIf="errors.max">
      {{ abbreviatedLabel }} {{ errors.max.dataType === 'date' ? 'cannot be after' : 'must below' }}
      {{
        errors.max.label ||
          (displayDollarSign ? '$' : '') + (errors.max.dataType === 'date' ? (errors.max.max | date) : errors.max.max)
      }}.
    </ng-container>

    <ng-container *ngIf="errors.shouldMatch"
      >{{ abbreviatedLabel }} doesn't match {{ errors.shouldMatch.label }}.</ng-container
    >
    <ng-container *ngIf="errors.shouldNotMatch"
      >{{ abbreviatedLabel }} matches {{ errors.shouldNotMatch.label }}.</ng-container
    >
    <ng-container *ngIf="errors.invalid_email">Invalid email format.</ng-container>
    <ng-container *ngIf="errors.nonUniqueUserEmail">User already exists.</ng-container>
    <ng-container *ngIf="errors.invalidIdLength">{{ errors.invalidIdLength }}.</ng-container>
    <ng-container *ngIf="errors.cannotBeNo">The answer cannot be no when you have employment.</ng-container>
    <ng-container *ngIf="errors.needsEnterOneEmployer">Enter at least one employer.</ng-container>
  </div>
</div>
