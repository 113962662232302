export interface Scale {
  Ratings?: { value: number; displayValue: string; tooltip?: string }[];
  BadRatings?: number[];
  Disabled?: boolean;
}

const NA_RATING = { value: 0, displayValue: 'N/A', tooltip: 'Not applicable/unknown' };

export const OneToFiveScale: Scale = {
  Ratings: [
    { value: 1, displayValue: '1', tooltip: 'Needs improvement' },
    { value: 2, displayValue: '2', tooltip: 'Fair, would like to develop' },
    { value: 3, displayValue: '3', tooltip: 'Average' },
    { value: 4, displayValue: '4', tooltip: 'Very good' },
    { value: 5, displayValue: '5', tooltip: 'Excellent/strength' },
  ],
  BadRatings: [1, 2],
};

export const OneToFiveScaleWithNA: Scale = {
  Ratings: [...(OneToFiveScale.Ratings ?? []), NA_RATING],
  BadRatings: OneToFiveScale.BadRatings,
};

/**
 * No/Yes.  Yes is the bad answer.
 */
export const YesNoScale: Scale = {
  Ratings: [
    { value: 2, displayValue: 'No' },
    { value: 1, displayValue: 'Yes' },
  ],
  BadRatings: [1],
};

/**
 * No/Yes/NA.  Yes is the bad answer.
 */
export const YesNoScaleWithNa: Scale = {
  Ratings: [...(YesNoScale.Ratings ?? []), NA_RATING],
  BadRatings: YesNoScale.BadRatings,
};

/**
 * No/Yes.  No is the bad answer.
 */
export const NoYesScale: Scale = {
  Ratings: [
    { value: 1, displayValue: 'No' },
    { value: 2, displayValue: 'Yes' },
  ],
  BadRatings: [1],
};

/**
 * No/Yes/NA.  No is the bad answer.
 */
export const NoYesScaleWithNa: Scale = {
  Ratings: [...(NoYesScale.Ratings ?? []), NA_RATING],
  BadRatings: NoYesScale.BadRatings,
};

/**
 * Record for internet access
 */
export const AccessToInternetScale: Scale = {
  Ratings: [
    { value: 2, displayValue: 'Home' },
    { value: 1, displayValue: 'Public' },
    { value: 0, displayValue: 'No' },
  ],
  BadRatings: [0],
};

/**
 * Record for telephone access
 */
export const AccessToTelephoneScale: Scale = {
  Ratings: [
    { value: 2, displayValue: 'Home' },
    { value: 1, displayValue: 'Public' },
    { value: 0, displayValue: 'No' },
  ],
  BadRatings: [0],
};
