import { User } from './user.entity';

export interface BaseEntity {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: User;
  updatedBy?: User;

  deletedAt?: Date;
  isDeleted?: boolean;
}
