export const ONLINE_APPLICATION_FRAGMENT = `
    _id
    submittedOn
    client {
        firstName
        lastName
        middleName
        birthdate
        email
        mySituation{
            id
            hasFinancialAdmin
            isEvictionDue
            isUtilityCutOffDue
            hasMedicalNeed
            hasDentalNeed
            leavingAbusiveSituation
            maritalStatus
            hasDependentChild
            municipality{
                name
                region
            }
            isCompletedOnBehalf
            onBehalfName
            onBehalfPhone{
                phoneNumber
                type
                isPrimary
            }
            onBehalfEmail
            movedToAlberta{
                isMovedToAlberta
                location
                date
            }
        }
        dependent{
            dependents{
                id
                firstName
                lastName
                middleName
                gender
                birthdate
                isRemoved
                attendingHighSchool
                livesWithParent
                hasIndianRegistration
                immigrationStatus
                immigrationStatusSponsored
                sponsorshipEndDate
            }
            hasSpouse
            biologicalChild
        }
        phoneNumbers{
            type
            phoneNumber
            isPrimary
        }
        gender
        immigrationStatus
        immigrationStatusSponsored
        sponsorshipEndDate
        hasIndianRegistration
        indianRegistration{
            band
            family
            position
            livesOnReserve
            receivesFundsFromBand
        }
        clientType
        spousePartner{
            firstName
            lastName
            middleName
            preferredName
            birthCertificateLastName
            birthdate
            gender
            socialInsuranceNumber
            immigrationStatus
            immigrationStatusSponsored
            sponsorshipEndDate
            hasIndianRegistration
            indianRegistration{
                band
                family
                position
                livesOnReserve
                receivesFundsFromBand
            }
        }
        socialInsuranceNumber
        hasIncome      
        hasAssets
        hasAdditionalAssets
        hasMedicalReport
        livingSituation{
            type
            monthlyRentAndUtilities
            monthlyMortgageAndUtilities
            monthlyRoomAndBoardAndUtilities
            paysForPowerElectricity
            powerElectricityCosts
            livesWithGrandparent
            livesWithParentStepParent
            livesWithAdultChildOrAdultGrandchild
            other
        }
        homeAddress{
            streetAddress
            suiteNumber
            city
            province
            postalCode
        }
        hasDifferentMailingAddress
        mailingAddress{
            streetAddress
            suiteNumber
            city
            province
            postalCode
        }  
    }
    assets {
        bankAccounts{
            applicantAmount
            spouseAmount
        }
        tfsa{
            applicantAmount
            spouseAmount
        }
        rrsp{
            applicantAmount
            spouseAmount
        }
        insurance{
            applicantAmount
            spouseAmount
        }
        stocksAndBonds{
            applicantAmount
            spouseAmount
        }
        uncashedChequesOrCashOnHand{
        applicantAmount
            spouseAmount
        }
    }
    additionalAssets {
        vehicle{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
        recreationalVehicle{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
        property{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
        farm{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
        business{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
        other{
            assets {
                type
                name
                spouseAmount
                applicantAmount
            }
        }
    }
`;
