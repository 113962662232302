<div [attr.data-cy]="getDataCy(dataCy, documentCategory.key, 'uploader')" class="dragdrop-component">
  <label class="label" [innerHtml]="getUploaderLabel()"> </label>

  <!--The initWithError check is to show the optout if the user skips all the way to review without going to a step
  and comes back with an error. Remove that if in that case we still do not want to show the checkbox-->
  <div
    data-cy="optOut"
    class="optout"
    *ngIf="
      optOutLabel &&
      (initOptOut || initWithError || alwaysShowOptOut) &&
      !optOutDisabled(documentCategory.key) &&
      !uploading
    "
  >
    <mat-checkbox
      [attr.data-cy]="getDataCy(dataCy, documentCategory.key, 'optOut')"
      [disabled]="optOutDisabled(documentCategory.key)"
      [checked]="getOptOutChecked(documentCategory.key)"
      (change)="onOptOutChanged($event, documentCategory.key)"
      ><span data-cy="optOutLabel" class="optout-text">{{ optOutLabel }}</span></mat-checkbox
    >
  </div>
  <ng-container *ngIf="documentCategory.value?.isRemoving && formGroup.controls[documentCategory.key].value">
    <div class="error-message-container bottom">
      <div class="error-text">{{ deleteMessage }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!documentCategory.value?.isRemoving">
    <div class="file-upload-row-container" *ngIf="showAddDocumentsButton()">
      <button data-cy="addAdditionalDocument" goa-button buttonType="tertiary" (click)="onUploadMore()">
        + Add additional document
      </button>
    </div>
    <div class="file-upload-container" *ngIf="!hasUploadedDocument || uploadMore || uploading || skipUploadMore">
      <div class="document-type-dropdown" [hidden]="uploading">
        <div
          class="default-document-type"
          *ngIf="documentCategory.value.documentTypes.length <= 1 && !documentCategory.value.hideDocumentTypeLabel"
        >
          {{
            (documentCategory.value.documentTypes.length === 0 && documentCategory.value.displayValue) ||
              documentCategory.value.documentTypes[0]
          }}
        </div>

        <mat-select
          *ngIf="documentCategory.value.documentTypes.length > 1"
          role="combobox"
          [formControl]="documentTypeFormControl"
          data-cy="documentType"
          aria-label="Document Type"
          [attr.aria-label]="documentCategory.value.displayValue"
          placeholder="{{ selectOneText }}"
          (selectionChange)="onDocumentTypeChange()"
        >
          <mat-option [attr.role]="textbox" disabled value="">{{ selectOneText }}</mat-option>
          <mat-option
            style="height: unset; white-space: normal"
            *ngFor="let type of documentCategory.value.documentTypes"
            [value]="type"
          >
            {{ type }}
          </mat-option>
        </mat-select>
        <div class="helper-text">{{ documentCategory.value.helpText }}</div>
      </div>
      <div>
        <div class="canvas-container" *ngIf="displayCanvas()">
          <div
            class="container"
            iesDragDrop
            (fileDropped)="fileUploadEvent($event, 'canvas')"
            *ngIf="isUploadEnabled"
            [class]="canvasUploaderState"
            data-cy="'uploadDocument'"
          >
            <input
              [attr.aria-label]="getUploaderLabel(true) + ' file chooser'"
              type="file"
              class="fileDropRef"
              [accept]="_accept"
              multiple
              [disabled]="canvasUploaderState === canvasStateDisabled"
              (change)="fileUploadEvent($event, 'browse')"
            />
            <div class="inner-container">
              <div class="main-canvas">
                <div class="canvas-content">
                  <div class="canvas-icon"><mat-icon svgIcon="common-cloud-upload"></mat-icon></div>
                  <!-- <div class="canvas-text">Drag and drop file here or Browse files</div> -->
                  <div class="canvas-text">Browse or drop files</div>
                  <div class="canvas-validfiles">(PDF, JPEG, JPG, PNG, or TIFF)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <common-document-progress
          [documentsInProgress]="documentsInProgress"
          [useLegacy]="false"
        ></common-document-progress>
        <!-- <div class="helper-text">({{ acceptDisplay }})</div> -->
      </div>
    </div>
    <div class="error-message-container bottom" *ngIf="!uploading">
      <!-- TEST: {{ canvasStateCurrent }} -->
      <ng-container *ngIf="showRequiredErrorMessage && !anyFileErrors()">
        <div class="error-text">{{ getRequiredErrorMessage }}</div>
      </ng-container>
    </div>

    <div class="error-message-container bottom" *ngIf="!uploading && anyFileErrors()">
      <ng-container *ngIf="fileErrors('maxFileSize')">
        <div class="error-text">
          Unsupported file size. Please try again with file size under
          {{ fileService.formatBytes(maxFileSize) }}
          and greater than 0 bytes.
        </div>
      </ng-container>
      <ng-container *ngIf="fileErrors('fileExtension')">
        <div class="error-text">
          Unsupported file format. Please try again with one of the following file types: {{ acceptDisplay }}.
        </div>
      </ng-container>
      <ng-container *ngIf="fileErrors('mimeType')">
        <div class="error-text">
          Unsupported file mime type. Please try again with one of the following file types: {{ acceptDisplay }}.
        </div>
      </ng-container>
      <ng-container *ngIf="fileErrors('protectedPdf')">
        <div class="error-text">PDF is password protected. Please remove the password and try again.</div>
      </ng-container>
      <ng-container *ngIf="fileErrors('invalidPdf')">
        <div class="error-text">PDF is invalid. Please select another file.</div>
      </ng-container>
    </div>
  </ng-container>
  <div class="document-list-container">
    <ng-content></ng-content>
  </div>
</div>
