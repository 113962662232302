import { Lookup } from './lookup.interface';
export const BenefitsHeldReasons: { [name: string]: Lookup } = {
  clientDidNotCompleteMonthlyReporting: { displayValue: 'Client did not complete monthly reporting' },
  incomeAssetConcerns: { displayValue: 'Income/asset concerns' },
  otherEligibilityConcerns: { displayValue: 'Other eligibility concerns (incarceration, out of province, etc)' },
  spouseNeedsToBeAdded: { displayValue: 'Spouse needs to be added' },
  clientDidNotSubmitDocumentation: { displayValue: 'Client did not submit documentation' },
  clientNoncompliantWithActionPlan: { displayValue: 'Client non-compliant with Action Plan' },
  napCompliance: { displayValue: 'NAP Compliance' },
};
