import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ContextProvider } from '../../services/context-provider/context.provider';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { ContextService } from '../../../ui-shared-components.services';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard {
  defaultNotAuthorized = '/not-authorized';

  constructor(
    protected readonly router: Router,
    private contextProvider: ContextProvider,
    protected readonly contextService: ContextService,
    protected readonly authenticationService: AuthenticationService
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const isAuthenticated = this.authenticationService.isAuthenticated();
    const checkToken = await this.authenticationService.checkRefreshToken(); //also need to check if token is expired

    if (isAuthenticated && checkToken !== null) {
      let user = await lastValueFrom(this.contextProvider.getCurrentUser().pipe(first()));

      //this will be undefined if AuthenticationGuard is not executed before AuthorizationGuard.
      //add fallback to make this guard a bit more resilient.
      if (!user) {
        //fallback method to check user profile from user token
        user = await this.contextService.getContextUserProfile(false);
        //if getCurrentUser is not being set, set it here
        this.contextProvider.setCurrentUser(user);
      }

      if (user) {
        const allowedRoles = route.data['allowedRoles'].map((r) => r.code);
        const matchingRoles = allowedRoles?.filter((allowedRole) => user.roles.includes(allowedRole));
        if (matchingRoles?.length > 0) return true;
      }
    } else {
      this.contextProvider.setCurrentUser(null);
    }

    //whitelist so default to denied
    const notAuthorizedPage = route?.data?.notAuthorizedPage ?? this.defaultNotAuthorized;
    this.router.navigate([notAuthorizedPage]);
    return false;
  }
}
