import { AdditionalAssetTypes } from '../additional-asset-types';
import { AssetLookup } from '../asset-lookup.interface.';
import { AssetTypes } from '../asset-types';

export const CaseAssetTypes: { [name: string]: AssetLookup } = {
  primary: {
    displayValue: 'Primary assets',
    //Get all primary asset types except funeral
    subTypes: Object.assign(
      {},
      ...Object.entries(AssetTypes)
        .filter(([k]) => k != 'funeral')
        .map(([k, v]) => ({ [k]: v }))
    ),
    documentTypes: [],
  },
  ...AdditionalAssetTypes,
};
