<goa-callout type="information" [heading]="title" *ngIf="!messageDialog" data-cy="title">
  <div mat-dialog-content [innerHTML]="description" data-cy="content"></div>
  <div mat-dialog-actions>
    <div></div>
    <button goa-button buttonSize="small" mat-dialog-close class="goa-button compact" data-cy="ok-button">Okay</button>
  </div>
</goa-callout>
<ng-container *ngIf="messageDialog">
  <h3 mat-dialog-title data-cy="title">{{ title }}</h3>
  <div mat-dialog-content data-cy="description" [innerHTML]="description"></div>

  <div mat-dialog-actions>
    <div></div>
    <button goa-button mat-dialog-close class="goa-button" data-cy="ok-button">Okay</button>
  </div>
</ng-container>
