import { Lookup } from './lookup.interface';
export interface LanguageLookup extends Lookup {
  lmtaDisplayValue?: string;
  edbId?: string;
  order: number;
}
const BaseLanguages: { [name: string]: LanguageLookup } = {
  english: { displayValue: 'English', lmtaDisplayValue: 'English Only', order: 1, code: 'english', edbId: '1' },
  french: { displayValue: 'French', lmtaDisplayValue: 'French Only', order: 2, code: 'french', edbId: '2' },
};

export const Languages: { [name: string]: LanguageLookup } = {
  ...BaseLanguages,
  other: { displayValue: 'Other', lmtaDisplayValue: 'Not a federal official language', order: 4, code: 'other' },
};

export const LmtaLanguages: { [name: string]: LanguageLookup } = {
  ...Languages,
  both: { displayValue: 'English and French', lmtaDisplayValue: 'English and French', order: 3 },
};

//Note: The order is not used. It represents the LANGUAGE_TYPE_ID from the EDB language list.
export const ClientLanguages: { [name: string]: LanguageLookup } = {
  ...BaseLanguages,
  abkhazian: { code: 'abkhazian', displayValue: 'Abkhazian', order: 494 },
  achinese: { code: 'achinese', displayValue: 'Achinese', order: 479 },
  acoli: { code: 'acoli', displayValue: 'Acoli', order: 480 },
  adangme: { code: 'adangme', displayValue: 'Adangme', order: 481 },
  afar: { code: 'afar', displayValue: 'Afar', order: 482 },
  afrihili: { code: 'afrihili', displayValue: 'Afrihili', order: 483 },
  afrikaans: { code: 'afrikaans', displayValue: 'Afrikaans', order: 484 },
  afroAsiaticOther: { code: 'afroAsiaticOther', displayValue: 'Afro-Asiatic (Other)', order: 485 },
  akan: { code: 'akan', displayValue: 'Akan', order: 486 },
  akkadian: { code: 'akkadian', displayValue: 'Akkadian', order: 487 },
  albanian: { code: 'albanian', displayValue: 'Albanian', order: 488 },
  aleut: { code: 'aleut', displayValue: 'Aleut', order: 489 },
  algonquianLanguages: {
    code: 'algonquianLanguages',
    displayValue: 'Algonquian languages',

    order: 490,
  },
  altaicOther: { code: 'altaicOther', displayValue: 'Altaic (Other)', order: 491 },
  amharic: { code: 'amharic', displayValue: 'Amharic', order: 492 },
  apacheLanguages: { code: 'apacheLanguages', displayValue: 'Apache languages', order: 493 },
  arabic: { code: 'arabic', displayValue: 'Arabic', order: 11 },
  aragonese: { code: 'aragonese', displayValue: 'Aragonese', order: 18 },
  aramaic: { code: 'aramaic', displayValue: 'Aramaic', order: 19 },
  arapaho: { code: 'arapaho', displayValue: 'Arapaho', order: 20 },
  araucanian: { code: 'araucanian', displayValue: 'Araucanian', order: 21 },
  arawak: { code: 'arawak', displayValue: 'Arawak', order: 22 },
  armenian: { code: 'armenian', displayValue: 'Armenian', order: 23 },
  artificialOther: { code: 'artificialOther', displayValue: 'Artificial (Other)', order: 24 },
  assamese: { code: 'assamese', displayValue: 'Assamese', order: 25 },
  asturian: { code: 'asturian', displayValue: 'Asturian', order: 26 },
  athapascanLanguages: {
    code: 'athapascanLanguages',
    displayValue: 'Athapascan languages',

    order: 27,
  },
  australianLanguages: {
    code: 'australianLanguages',
    displayValue: 'Australian languages',

    order: 28,
  },
  austronesianOther: { code: 'austronesianOther', displayValue: 'Austronesian (Other)', order: 29 },
  avaric: { code: 'avaric', displayValue: 'Avaric', order: 30 },
  avestan: { code: 'avestan', displayValue: 'Avestan', order: 31 },
  awadhi: { code: 'awadhi', displayValue: 'Awadhi', order: 32 },
  aymara: { code: 'aymara', displayValue: 'Aymara', order: 33 },
  azerbaijani: { code: 'azerbaijani', displayValue: 'Azerbaijani', order: 34 },
  bable: { code: 'bable', displayValue: 'Bable', order: 35 },
  balinese: { code: 'balinese', displayValue: 'Balinese', order: 36 },
  balticOther: { code: 'balticOther', displayValue: 'Baltic (Other)', order: 37 },
  baluchi: { code: 'baluchi', displayValue: 'Baluchi', order: 38 },
  bambara: { code: 'bambara', displayValue: 'Bambara', order: 39 },
  bamilekeLanguages: { code: 'bamilekeLanguages', displayValue: 'Bamileke languages', order: 40 },
  banda: { code: 'banda', displayValue: 'Banda', order: 41 },
  bantuOther: { code: 'bantuOther', displayValue: 'Bantu (Other)', order: 42 },
  basa: { code: 'basa', displayValue: 'Basa', order: 43 },
  bashkir: { code: 'bashkir', displayValue: 'Bashkir', order: 44 },
  basque: { code: 'basque', displayValue: 'Basque', order: 45 },
  batakIndonesia: { code: 'batakIndonesia', displayValue: 'Batak (Indonesia)', order: 46 },
  beja: { code: 'beja', displayValue: 'Beja', order: 47 },
  belarusian: { code: 'belarusian', displayValue: 'Belarusian', order: 48 },
  bemba: { code: 'bemba', displayValue: 'Bemba', order: 49 },
  bengali: { code: 'bengali', displayValue: 'Bengali', order: 50 },
  berberOther: { code: 'berberOther', displayValue: 'Berber (Other)', order: 51 },
  bhojpuri: { code: 'bhojpuri', displayValue: 'Bhojpuri', order: 52 },
  bihari: { code: 'bihari', displayValue: 'Bihari', order: 53 },
  bikol: { code: 'bikol', displayValue: 'Bikol', order: 54 },
  bini: { code: 'bini', displayValue: 'Bini', order: 55 },
  bislama: { code: 'bislama', displayValue: 'Bislama', order: 56 },
  bokmalNorwegian: { code: 'bokmalNorwegian', displayValue: 'Bokmål, Norwegian', order: 57 },
  bosnian: { code: 'bosnian', displayValue: 'Bosnian', order: 58 },
  braj: { code: 'braj', displayValue: 'Braj', order: 59 },
  breton: { code: 'breton', displayValue: 'Breton', order: 60 },
  buginese: { code: 'buginese', displayValue: 'Buginese', order: 61 },
  bulgarian: { code: 'bulgarian', displayValue: 'Bulgarian', order: 62 },
  buriat: { code: 'buriat', displayValue: 'Buriat', order: 63 },
  burmese: { code: 'burmese', displayValue: 'Burmese', order: 64 },
  caddo: { code: 'caddo', displayValue: 'Caddo', order: 65 },
  carib: { code: 'carib', displayValue: 'Carib', order: 66 },
  castilian: { code: 'castilian', displayValue: 'Castilian', order: 67 },
  catalan: { code: 'catalan', displayValue: 'Catalan', order: 68 },
  caucasianOther: { code: 'caucasianOther', displayValue: 'Caucasian (Other)', order: 69 },
  cebuano: { code: 'cebuano', displayValue: 'Cebuano', order: 70 },
  celticOther: { code: 'celticOther', displayValue: 'Celtic (Other)', order: 71 },
  centralAmericanIndianOther: {
    code: 'centralAmericanIndianOther',
    displayValue: 'Central American Indian (Other)',

    order: 72,
  },
  chagatai: { code: 'chagatai', displayValue: 'Chagatai', order: 73 },
  chamicLanguages: { code: 'chamicLanguages', displayValue: 'Chamic languages', order: 74 },
  chamorro: { code: 'chamorro', displayValue: 'Chamorro', order: 75 },
  chechen: { code: 'chechen', displayValue: 'Chechen', order: 76 },
  cherokee: { code: 'cherokee', displayValue: 'Cherokee', order: 77 },
  chewa: { code: 'chewa', displayValue: 'Chewa', order: 78 },
  cheyenne: { code: 'cheyenne', displayValue: 'Cheyenne', order: 79 },
  chibcha: { code: 'chibcha', displayValue: 'Chibcha', order: 80 },
  chichewa: { code: 'chichewa', displayValue: 'Chichewa', order: 81 },
  chineseMandarin: { code: 'chineseMandarin', displayValue: 'Chinese-Mandarin', order: 3 },
  chineseCantonese: { code: 'chineseCantonese', displayValue: 'Chinese-Cantonese', order: 3 },
  chinookJargon: { code: 'chinookJargon', displayValue: 'Chinook jargon', order: 83 },
  chipewyan: { code: 'chipewyan', displayValue: 'Chipewyan', order: 84 },
  choctaw: { code: 'choctaw', displayValue: 'Choctaw', order: 85 },
  chuang: { code: 'chuang', displayValue: 'Chuang', order: 86 },
  churchSlavic: { code: 'churchSlavic', displayValue: 'Church Slavic', order: 87 },
  churchSlavonic: { code: 'churchSlavonic', displayValue: 'Church Slavonic', order: 88 },
  chuukese: { code: 'chuukese', displayValue: 'Chuukese', order: 89 },
  chuvash: { code: 'chuvash', displayValue: 'Chuvash', order: 90 },
  coptic: { code: 'coptic', displayValue: 'Coptic', order: 91 },
  cornish: { code: 'cornish', displayValue: 'Cornish', order: 92 },
  corsican: { code: 'corsican', displayValue: 'Corsican', order: 93 },
  cree: { code: 'cree', displayValue: 'Cree', order: 16 },
  creek: { code: 'creek', displayValue: 'Creek', order: 95 },
  creolesAndPidginsEnglishBasedOther: {
    code: 'creolesAndPidginsEnglishBasedOther',
    displayValue: 'Creoles and pidgins, English-based (Other)',

    order: 97,
  },
  creolesAndPidginsFrenchBasedOther: {
    code: 'creolesAndPidginsFrenchBasedOther',
    displayValue: 'Creoles and pidgins, French-based (Other)',

    order: 98,
  },
  creolesAndPidginsPortugueseBasedOther: {
    code: 'creolesAndPidginsPortugueseBasedOther',
    displayValue: 'Creoles and pidgins, Portuguese-based (Other)',

    order: 99,
  },
  creolesAndPidginsOther: {
    code: 'creolesAndPidginsOther',
    displayValue: 'Creoles and pidgins(Other)',

    order: 96,
  },
  croatian: { code: 'croatian', displayValue: 'Croatian', order: 100 },
  cushiticOther: { code: 'cushiticOther', displayValue: 'Cushitic (Other)', order: 101 },
  czech: { code: 'czech', displayValue: 'Czech', order: 102 },
  dakota: { code: 'dakota', displayValue: 'Dakota', order: 103 },
  danish: { code: 'danish', displayValue: 'Danish', order: 104 },
  dari: { code: 'dari', displayValue: 'Dari', order: 105 },
  dargwa: { code: 'dargwa', displayValue: 'Dargwa', order: 105 },
  dayak: { code: 'dayak', displayValue: 'Dayak', order: 106 },
  delaware: { code: 'delaware', displayValue: 'Delaware', order: 107 },
  dinka: { code: 'dinka', displayValue: 'Dinka', order: 108 },
  divehi: { code: 'divehi', displayValue: 'Divehi', order: 109 },
  dogri: { code: 'dogri', displayValue: 'Dogri', order: 110 },
  dogrib: { code: 'dogrib', displayValue: 'Dogrib', order: 111 },
  dravidianOther: { code: 'dravidianOther', displayValue: 'Dravidian (Other)', order: 112 },
  duala: { code: 'duala', displayValue: 'Duala', order: 113 },
  dutch: { code: 'dutch', displayValue: 'Dutch', order: 12 },
  dutchMiddleCa1050To1350: {
    code: 'dutchMiddleCa1050To1350',
    displayValue: 'Dutch, Middle (ca. 1050-1350)',

    order: 115,
  },
  dyula: { code: 'dyula', displayValue: 'Dyula', order: 116 },
  dzongkha: { code: 'dzongkha', displayValue: 'Dzongkha', order: 117 },
  efik: { code: 'efik', displayValue: 'Efik', order: 118 },
  egyptianAncient: { code: 'egyptianAncient', displayValue: 'EgyptianAncient', order: 119 },
  ekajuk: { code: 'ekajuk', displayValue: 'Ekajuk', order: 120 },
  elamite: { code: 'elamite', displayValue: 'Elamite', order: 121 },
  englishMiddle1100To1500: {
    code: 'englishMiddle1100To1500',
    displayValue: 'English, Middle (1100-1500)',

    order: 123,
  },
  englishOldCa450To1100: {
    code: 'englishOldCa450To1100',
    displayValue: 'English, Old (ca.450-1100)',

    order: 124,
  },
  esperanto: { code: 'esperanto', displayValue: 'Esperanto', order: 125 },
  estonian: { code: 'estonian', displayValue: 'Estonian', order: 126 },
  ewe: { code: 'ewe', displayValue: 'Ewe', order: 127 },
  ewondo: { code: 'ewondo', displayValue: 'Ewondo', order: 128 },
  fang: { code: 'fang', displayValue: 'Fang', order: 129 },
  fanti: { code: 'fanti', displayValue: 'Fanti', order: 130 },
  faroese: { code: 'faroese', displayValue: 'Faroese', order: 131 },
  fijian: { code: 'fijian', displayValue: 'Fijian', order: 132 },
  finnish: { code: 'finnish', displayValue: 'Finnish', order: 133 },
  finnoUgrianOther: { code: 'finnoUgrianOther', displayValue: 'Finno-Ugrian (Other)', order: 134 },
  fon: { code: 'fon', displayValue: 'Fon', order: 135 },
  frenchMiddleCa1400To1600: {
    code: 'frenchMiddleCa1400To1600',
    displayValue: 'French, Middle (ca.1400-1600)',

    order: 137,
  },
  frenchOld842Ca1400: {
    code: 'frenchOld842Ca1400',
    displayValue: 'French, Old (842-ca.1400)',

    order: 138,
  },
  frisian: { code: 'frisian', displayValue: 'Frisian', order: 139 },
  friulian: { code: 'friulian', displayValue: 'Friulian', order: 140 },
  fulah: { code: 'fulah', displayValue: 'Fulah', order: 141 },
  ga: { code: 'ga', displayValue: 'Ga', order: 142 },
  gaelic: { code: 'gaelic', displayValue: 'Gaelic', order: 143 },
  gallegan: { code: 'gallegan', displayValue: 'Gallegan', order: 144 },
  ganda: { code: 'ganda', displayValue: 'Ganda', order: 145 },
  gayo: { code: 'gayo', displayValue: 'Gayo', order: 146 },
  gbaya: { code: 'gbaya', displayValue: 'Gbaya', order: 147 },
  geez: { code: 'geez', displayValue: 'Geez', order: 148 },
  georgian: { code: 'georgian', displayValue: 'Georgian', order: 149 },
  german: { code: 'german', displayValue: 'German', order: 5 },
  germanLow: { code: 'germanLow', displayValue: 'German, Low', order: 151 },
  germanMiddleHighCa10501500: {
    code: 'germanMiddleHighCa10501500',
    displayValue: 'German, Middle High (ca.1050-1500)',

    order: 152,
  },
  germanOldHighCa750To1050: {
    code: 'germanOldHighCa750To1050',
    displayValue: 'German, Old High (ca.750-1050)',

    order: 153,
  },
  germanicOther: { code: 'germanicOther', displayValue: 'Germanic (Other)', order: 154 },
  gikuyu: { code: 'gikuyu', displayValue: 'Gikuyu', order: 155 },
  gilbertese: { code: 'gilbertese', displayValue: 'Gilbertese', order: 156 },
  gondi: { code: 'gondi', displayValue: 'Gondi', order: 157 },
  gorontalo: { code: 'gorontalo', displayValue: 'Gorontalo', order: 158 },
  gothic: { code: 'gothic', displayValue: 'Gothic', order: 159 },
  grebo: { code: 'grebo', displayValue: 'Grebo', order: 160 },
  greek: { code: 'greek', displayValue: 'Greek', order: 14 },
  greekAncientTo1453: {
    code: 'greekAncientTo1453',
    displayValue: 'Greek, Ancient (to 1453)',

    order: 161,
  },
  guarani: { code: 'guarani', displayValue: 'Guarani', order: 163 },
  gujarati: { code: 'gujarati', displayValue: 'Gujarati', order: 164 },
  gwichin: { code: 'gwichin', displayValue: 'Gwich´in', order: 165 },
  haida: { code: 'haida', displayValue: 'Haida', order: 166 },
  hausa: { code: 'hausa', displayValue: 'Hausa', order: 167 },
  hawaiian: { code: 'hawaiian', displayValue: 'Hawaiian', order: 168 },
  hebrew: { code: 'hebrew', displayValue: 'Hebrew', order: 169 },
  herero: { code: 'herero', displayValue: 'Herero', order: 170 },
  hiligaynon: { code: 'hiligaynon', displayValue: 'Hiligaynon', order: 171 },
  himachali: { code: 'himachali', displayValue: 'Himachali', order: 172 },
  hindi: { code: 'hindi', displayValue: 'Hindi', order: 173 },
  hiriMotu: { code: 'hiriMotu', displayValue: 'Hiri Motu', order: 174 },
  hittite: { code: 'hittite', displayValue: 'Hittite', order: 175 },
  hmong: { code: 'hmong', displayValue: 'Hmong', order: 176 },
  hungarian: { code: 'hungarian', displayValue: 'Hungarian', order: 177 },
  hupa: { code: 'hupa', displayValue: 'Hupa', order: 178 },
  iban: { code: 'iban', displayValue: 'Iban', order: 179 },
  icelandic: { code: 'icelandic', displayValue: 'Icelandic', order: 180 },
  ido: { code: 'ido', displayValue: 'Ido', order: 181 },
  igbo: { code: 'igbo', displayValue: 'Igbo', order: 182 },
  ijo: { code: 'ijo', displayValue: 'Ijo', order: 183 },
  iloko: { code: 'iloko', displayValue: 'Iloko', order: 184 },
  inariSami: { code: 'inariSami', displayValue: 'Inari Sami', order: 185 },
  indicOther: { code: 'indicOther', displayValue: 'Indic (Other)', order: 186 },
  indoEuropeanOther: {
    code: 'indoEuropeanOther',
    displayValue: 'Indo-European (Other)',

    order: 187,
  },
  indonesian: { code: 'indonesian', displayValue: 'Indonesian', order: 188 },
  ingush: { code: 'ingush', displayValue: 'Ingush', order: 189 },
  interlingua: {
    code: 'interlingua',
    displayValue: 'Interlingua (International Auxiliary Language Association)',

    order: 190,
  },
  interlingue: { code: 'interlingue', displayValue: 'Interlingue', order: 191 },
  inuktitut: { code: 'inuktitut', displayValue: 'Inuktitut', order: 17 },
  inupiaq: { code: 'inupiaq', displayValue: 'Inupiaq', order: 193 },
  iranianOther: { code: 'iranianOther', displayValue: 'Iranian (Other)', order: 194 },
  irish: { code: 'irish', displayValue: 'Irish', order: 195 },
  irishMiddle900To1200: {
    code: 'irishMiddle900To1200',
    displayValue: 'Irish, Middle (900-1200)',

    order: 196,
  },
  irishOldTo900: { code: 'irishOldTo900', displayValue: 'Irish, Old (to 900)', order: 197 },
  iroquoianLanguages: {
    code: 'iroquoianLanguages',
    displayValue: 'Iroquoian languages',

    order: 198,
  },
  italian: { code: 'italian', displayValue: 'Italian', order: 4 },
  japanese: { code: 'japanese', displayValue: 'Japanese', order: 200 },
  javanese: { code: 'javanese', displayValue: 'Javanese', order: 201 },
  judeoArabic: { code: 'judeoArabic', displayValue: 'Judeo-Arabic', order: 202 },
  judeoPersian: { code: 'judeoPersian', displayValue: 'Judeo-Persian', order: 203 },
  kabardian: { code: 'kabardian', displayValue: 'Kabardian', order: 204 },
  kabyle: { code: 'kabyle', displayValue: 'Kabyle', order: 205 },
  kachin: { code: 'kachin', displayValue: 'Kachin', order: 206 },
  kalaallisut: { code: 'kalaallisut', displayValue: 'Kalaallisut', order: 207 },
  kamba: { code: 'kamba', displayValue: 'Kamba', order: 208 },
  kannada: { code: 'kannada', displayValue: 'Kannada', order: 209 },
  kanuri: { code: 'kanuri', displayValue: 'Kanuri', order: 210 },
  karaKalpak: { code: 'karaKalpak', displayValue: 'Kara-Kalpak', order: 211 },
  karen: { code: 'karen', displayValue: 'Karen', order: 212 },
  kashmiri: { code: 'kashmiri', displayValue: 'Kashmiri', order: 213 },
  kawi: { code: 'kawi', displayValue: 'Kawi', order: 214 },
  kazakh: { code: 'kazakh', displayValue: 'Kazakh', order: 215 },
  khasi: { code: 'khasi', displayValue: 'Khasi', order: 216 },
  khmer: { code: 'khmer', displayValue: 'Khmer', order: 217 },
  khoisanOther: { code: 'khoisanOther', displayValue: 'Khoisan (Other)', order: 218 },
  khotanese: { code: 'khotanese', displayValue: 'Khotanese', order: 219 },
  kikuyu: { code: 'kikuyu', displayValue: 'Kikuyu', order: 220 },
  kimbundu: { code: 'kimbundu', displayValue: 'Kimbundu', order: 221 },
  kinyarwanda: { code: 'kinyarwanda', displayValue: 'Kinyarwanda', order: 222 },
  kirghiz: { code: 'kirghiz', displayValue: 'Kirghiz', order: 223 },
  komi: { code: 'komi', displayValue: 'Komi', order: 224 },
  kongo: { code: 'kongo', displayValue: 'Kongo', order: 225 },
  konkani: { code: 'konkani', displayValue: 'Konkani', order: 226 },
  korean: { code: 'korean', displayValue: 'Korean', order: 227 },
  kosraean: { code: 'kosraean', displayValue: 'Kosraean', order: 228 },
  kpelle: { code: 'kpelle', displayValue: 'Kpelle', order: 229 },
  kru: { code: 'kru', displayValue: 'Kru', order: 230 },
  kuanyama: { code: 'kuanyama', displayValue: 'Kuanyama', order: 231 },
  kumyk: { code: 'kumyk', displayValue: 'Kumyk', order: 232 },
  kurdish: { code: 'kurdish', displayValue: 'Kurdish', order: 233 },
  Kurmanji: { code: 'Kurmanji', displayValue: 'Kurmanji', order: 234 },
  kurukh: { code: 'kurukh', displayValue: 'Kurukh', order: 235 },
  kutenai: { code: 'kutenai', displayValue: 'Kutenai', order: 236 },
  kwanyama: { code: 'kwanyama', displayValue: 'Kwanyama', order: 237 },
  ladino: { code: 'ladino', displayValue: 'Ladino', order: 238 },
  lahnda: { code: 'lahnda', displayValue: 'Lahnda', order: 239 },
  lamba: { code: 'lamba', displayValue: 'Lamba', order: 240 },
  lao: { code: 'lao', displayValue: 'Lao', order: 241 },
  latin: { code: 'latin', displayValue: 'Latin', order: 242 },
  latvian: { code: 'latvian', displayValue: 'Latvian', order: 243 },
  letzeburgesch: { code: 'letzeburgesch', displayValue: 'Letzeburgesch', order: 244 },
  lezghian: { code: 'lezghian', displayValue: 'Lezghian', order: 245 },
  limburgan: { code: 'limburgan', displayValue: 'Limburgan', order: 246 },
  limburger: { code: 'limburger', displayValue: 'Limburger', order: 247 },
  limburgish: { code: 'limburgish', displayValue: 'Limburgish', order: 248 },
  lingala: { code: 'lingala', displayValue: 'Lingala', order: 249 },
  lithuanian: { code: 'lithuanian', displayValue: 'Lithuanian', order: 250 },
  lowGerman: { code: 'lowGerman', displayValue: 'Low German', order: 251 },
  lowSaxon: { code: 'lowSaxon', displayValue: 'Low Saxon', order: 252 },
  lozi: { code: 'lozi', displayValue: 'Lozi', order: 253 },
  lubaKatanga: { code: 'lubaKatanga', displayValue: 'Luba-Katanga', order: 254 },
  lubaLulua: { code: 'lubaLulua', displayValue: 'Luba-Lulua', order: 255 },
  luiseno: { code: 'luiseno', displayValue: 'Luiseno', order: 256 },
  luleSami: { code: 'luleSami', displayValue: 'Lule Sami', order: 257 },
  lunda: { code: 'lunda', displayValue: 'Lunda', order: 258 },
  luoKenyaAndTanzania: {
    code: 'luoKenyaAndTanzania',
    displayValue: 'Luo (Kenya and Tanzania)',
    order: 259,
  },
  lushai: { code: 'lushai', displayValue: 'Lushai', order: 260 },
  luxembourgish: { code: 'luxembourgish', displayValue: 'Luxembourgish', order: 261 },
  macedonian: { code: 'macedonian', displayValue: 'Macedonian', order: 262 },
  madurese: { code: 'madurese', displayValue: 'Madurese', order: 263 },
  magahi: { code: 'magahi', displayValue: 'Magahi', order: 264 },
  maithili: { code: 'maithili', displayValue: 'Maithili', order: 265 },
  makasar: { code: 'makasar', displayValue: 'Makasar', order: 266 },
  malagasy: { code: 'malagasy', displayValue: 'Malagasy', order: 267 },
  malay: { code: 'malay', displayValue: 'Malay', order: 268 },
  malayalam: { code: 'malayalam', displayValue: 'Malayalam', order: 269 },
  maltese: { code: 'maltese', displayValue: 'Maltese', order: 270 },
  manchu: { code: 'manchu', displayValue: 'Manchu', order: 271 },
  mandar: { code: 'mandar', displayValue: 'Mandar', order: 272 },
  mandingo: { code: 'mandingo', displayValue: 'Mandingo', order: 273 },
  manipuri: { code: 'manipuri', displayValue: 'Manipuri', order: 2734 },
  manoboLanguages: { code: 'manoboLanguages', displayValue: 'Manobo languages', order: 275 },
  manx: { code: 'manx', displayValue: 'Manx', order: 276 },
  maori: { code: 'maori', displayValue: 'Maori', order: 277 },
  marathi: { code: 'marathi', displayValue: 'Marathi', order: 278 },
  mari: { code: 'mari', displayValue: 'Mari', order: 279 },
  marshallese: { code: 'marshallese', displayValue: 'Marshallese', order: 280 },
  marwari: { code: 'marwari', displayValue: 'Marwari', order: 281 },
  masai: { code: 'masai', displayValue: 'Masai', order: 282 },
  mayanLanguages: { code: 'mayanLanguages', displayValue: 'Mayan languages', order: 283 },
  mende: { code: 'mende', displayValue: 'Mende', order: 284 },
  micmac: { code: 'micmac', displayValue: 'Micmac', order: 285 },
  minangkabau: { code: 'minangkabau', displayValue: 'Minangkabau', order: 286 },
  miscellaneousLanguages: {
    code: 'miscellaneousLanguages',
    displayValue: 'Miscellaneous languages',
    order: 287,
  },
  mohawk: { code: 'mohawk', displayValue: 'Mohawk', order: 288 },
  moldavian: { code: 'moldavian', displayValue: 'Moldavian', order: 289 },
  monKhmerOther: { code: 'monKhmerOther', displayValue: 'Mon-Khmer (Other)', order: 290 },
  mongo: { code: 'mongo', displayValue: 'Mongo', order: 291 },
  mongolian: { code: 'mongolian', displayValue: 'Mongolian', order: 292 },
  mossi: { code: 'mossi', displayValue: 'Mossi', order: 293 },
  multipleLanguages: { code: 'multipleLanguages', displayValue: 'Multiple languages', order: 293 },
  mundaLanguages: { code: 'mundaLanguages', displayValue: 'Munda languages', order: 294 },
  nahuatl: { code: 'nahuatl', displayValue: 'Nahuatl', order: 295 },
  nauru: { code: 'nauru', displayValue: 'Nauru', order: 296 },
  navaho: { code: 'navaho', displayValue: 'Navaho', order: 297 },
  navajo: { code: 'navajo', displayValue: 'Navajo', order: 298 },
  ndebeleNorth: { code: 'ndebeleNorth', displayValue: 'Ndebele, North', order: 299 },
  ndebeleSouth: { code: 'ndebeleSouth', displayValue: 'Ndebele, South', order: 300 },
  ndonga: { code: 'ndonga', displayValue: 'Ndonga', order: 301 },
  neapolitan: { code: 'neapolitan', displayValue: 'Neapolitan', order: 302 },
  nepali: { code: 'nepali', displayValue: 'Nepali', order: 303 },
  newari: { code: 'newari', displayValue: 'Newari', order: 304 },
  nias: { code: 'nias', displayValue: 'Nias', order: 305 },
  nigerKordofanianOther: {
    code: 'nigerKordofanianOther',
    displayValue: 'Niger-Kordofanian (Other)',

    order: 306,
  },
  niloSaharanOther: { code: 'niloSaharanOther', displayValue: 'Nilo-Saharan (Other)', order: 307 },
  niuean: { code: 'niuean', displayValue: 'Niuean', order: 308 },
  norseOld: { code: 'norseOld', displayValue: 'Norse, Old', order: 309 },
  northAmericanIndianOther: {
    code: 'northAmericanIndianOther',
    displayValue: 'North American Indian (Other)',

    order: 310,
  },
  northNdebele: { code: 'northNdebele', displayValue: 'North Ndebele', order: 312 },
  northernSami: { code: 'northernSami', displayValue: 'Northern Sami', order: 311 },
  norwegian: { code: 'norwegian', displayValue: 'Norwegian', order: 313 },
  norwegianBokmål: { code: 'norwegianBokmål', displayValue: 'Norwegian Bokmål', order: 314 },
  norwegianNynorsk: { code: 'norwegianNynorsk', displayValue: 'Norwegian Nynorsk', order: 315 },
  nubianLanguages: { code: 'nubianLanguages', displayValue: 'Nubian languages', order: 316 },
  nyamwezi: { code: 'nyamwezi', displayValue: 'Nyamwezi', order: 317 },
  nyanja: { code: 'nyanja', displayValue: 'Nyanja', order: 318 },
  nyankole: { code: 'nyankole', displayValue: 'Nyankole', order: 319 },
  nynorskNorwegian: { code: 'nynorskNorwegian', displayValue: 'Nynorsk, Norwegian', order: 320 },
  nyoro: { code: 'nyoro', displayValue: 'Nyoro', order: 321 },
  nzima: { code: 'nzima', displayValue: 'Nzima', order: 322 },
  occitanPost1500: { code: 'occitanPost1500', displayValue: 'Occitan (post 1500)', order: 323 },
  ojibwa: { code: 'ojibwa', displayValue: 'Ojibwa', order: 324 },
  oldBulgarian: { code: 'oldBulgarian', displayValue: 'Old Bulgarian', order: 325 },
  oldChurchSlavonic: { code: 'oldChurchSlavonic', displayValue: 'Old Church Slavonic', order: 326 },
  oldSlavonic: { code: 'oldSlavonic', displayValue: 'Old Slavonic', order: 327 },
  oriya: { code: 'oriya', displayValue: 'Oriya', order: 328 },
  oromo: { code: 'oromo', displayValue: 'Oromo', order: 329 },
  osage: { code: 'osage', displayValue: 'Osage', order: 330 },
  ossetian: { code: 'ossetian', displayValue: 'Ossetian', order: 331 },
  ossetic: { code: 'ossetic', displayValue: 'Ossetic', order: 332 },
  otomianLanguages: { code: 'otomianLanguages', displayValue: 'Otomian languages', order: 333 },
  pahlavi: { code: 'pahlavi', displayValue: 'Pahlavi', order: 334 },
  palauan: { code: 'palauan', displayValue: 'Palauan', order: 335 },
  pali: { code: 'pali', displayValue: 'Pali', order: 336 },
  pampanga: { code: 'pampanga', displayValue: 'Pampanga', order: 337 },
  pangasinan: { code: 'pangasinan', displayValue: 'Pangasinan', order: 338 },
  panjabi: { code: 'panjabi', displayValue: 'Punjabi', order: 9 },
  papiamento: { code: 'papiamento', displayValue: 'Papiamento', order: 340 },
  papuanOther: { code: 'papuanOther', displayValue: 'Papuan (Other)', order: 341 },
  pashto: { code: 'pashto', displayValue: 'Pashto', order: 341 },
  persian: { code: 'persian', displayValue: 'Farsi/Persian', order: 342 },
  persianOldCa600To400: {
    code: 'persianOldCa600To400',
    displayValue: 'Persian, Old (ca.600-400)',

    order: 343,
  },
  philippineOther: { code: 'philippineOther', displayValue: 'Philippine (Other)', order: 344 },
  phoenician: { code: 'phoenician', displayValue: 'Phoenician', order: 345 },
  pohnpeian: { code: 'pohnpeian', displayValue: 'Pohnpeian', order: 346 },
  polish: { code: 'polish', displayValue: 'Polish', order: 6 },
  portuguese: { code: 'portuguese', displayValue: 'Portuguese', order: 8 },
  prakritLanguages: { code: 'prakritLanguages', displayValue: 'Prakrit languages', order: 349 },
  provencal: { code: 'provencal', displayValue: 'Provençal', order: 350 },
  provencalOldTo1500: {
    code: 'provencalOldTo1500',
    displayValue: 'Provençal, Old (to 1500)',

    order: 351,
  },
  pushto: { code: 'pushto', displayValue: 'Pushto', order: 352 },
  quechua: { code: 'quechua', displayValue: 'Quechua', order: 353 },
  raetoRomance: { code: 'raetoRomance', displayValue: 'Raeto-Romance', order: 354 },
  rajasthani: { code: 'rajasthani', displayValue: 'Rajasthani', order: 355 },
  rapanui: { code: 'rapanui', displayValue: 'Rapanui', order: 356 },
  rarotongan: { code: 'rarotongan', displayValue: 'Rarotongan', order: 357 },
  romanceOther: { code: 'romanceOther', displayValue: 'Romance (Other)', order: 359 },
  romanian: { code: 'romanian', displayValue: 'Romanian', order: 360 },
  romany: { code: 'romany', displayValue: 'Romany', order: 361 },
  rundi: { code: 'rundi', displayValue: 'Kirundi/Rundi', order: 362 },
  russian: { code: 'russian', displayValue: 'Russian', order: 363 },
  salishanLanguages: { code: 'salishanLanguages', displayValue: 'Salishan languages', order: 364 },
  samaritanAramaic: { code: 'samaritanAramaic', displayValue: 'Samaritan Aramaic', order: 365 },
  samiLanguagesOther: {
    code: 'samiLanguagesOther',
    displayValue: 'Sami languages (Other)',

    order: 366,
  },
  samoan: { code: 'samoan', displayValue: 'Samoan', order: 367 },
  sandawe: { code: 'sandawe', displayValue: 'Sandawe', order: 368 },
  sango: { code: 'sango', displayValue: 'Sango', order: 369 },
  sanskrit: { code: 'sanskrit', displayValue: 'Sanskrit', order: 370 },
  santali: { code: 'santali', displayValue: 'Santali', order: 371 },
  sardinian: { code: 'sardinian', displayValue: 'Sardinian', order: 372 },
  sasak: { code: 'sasak', displayValue: 'Sasak', order: 373 },
  saxonLow: { code: 'saxonLow', displayValue: 'Saxon, Low', order: 374 },
  scots: { code: 'scots', displayValue: 'Scots', order: 375 },
  scottishGaelic: { code: 'scottishGaelic', displayValue: 'Scottish Gaelic', order: 376 },
  selkup: { code: 'selkup', displayValue: 'Selkup', order: 377 },
  semiticOther: { code: 'semiticOther', displayValue: 'Semitic (Other)', order: 378 },
  serbian: { code: 'serbian', displayValue: 'Serbian', order: 379 },
  serer: { code: 'serer', displayValue: 'Serer', order: 380 },
  shan: { code: 'shan', displayValue: 'Shan', order: 381 },
  shona: { code: 'shona', displayValue: 'Shona', order: 382 },
  sichuanYi: { code: 'sichuanYi', displayValue: 'Sichuan Yi', order: 383 },
  sidamo: { code: 'sidamo', displayValue: 'Sidamo', order: 384 },
  signLanguages: { code: 'signLanguages', displayValue: 'Sign languages', order: 385 },
  siksika: { code: 'siksika', displayValue: 'Siksika', order: 386 },
  sindhi: { code: 'sindhi', displayValue: 'Sindhi', order: 387 },
  sinhalese: { code: 'sinhalese', displayValue: 'Sinhalese', order: 388 },
  sinoTibetanOther: { code: 'sinoTibetanOther', displayValue: 'Sino-Tibetan (Other)', order: 389 },
  siouanLanguages: { code: 'siouanLanguages', displayValue: 'Siouan languages', order: 390 },
  skoltSami: { code: 'skoltSami', displayValue: 'Skolt Sami', order: 391 },
  slaveAthapascan: { code: 'slaveAthapascan', displayValue: 'Slave (Athapascan)', order: 392 },
  slavicOther: { code: 'slavicOther', displayValue: 'Slavic (Other)', order: 393 },
  slovak: { code: 'slovak', displayValue: 'Slovak', order: 394 },
  slovenian: { code: 'slovenian', displayValue: 'Slovenian', order: 395 },
  sogdian: { code: 'sogdian', displayValue: 'Sogdian', order: 396 },
  somali: { code: 'somali', displayValue: 'Somali', order: 397 },
  songhai: { code: 'songhai', displayValue: 'Songhai', order: 398 },
  soninke: { code: 'soninke', displayValue: 'Soninke', order: 399 },
  sorbianLanguages: { code: 'sorbianLanguages', displayValue: 'Sorbian languages', order: 400 },
  sothoNorthern: { code: 'sothoNorthern', displayValue: 'Sotho, Northern', order: 401 },
  sothoSouthern: { code: 'sothoSouthern', displayValue: 'Sotho, Southern', order: 402 },
  southAmericanIndianOther: {
    code: 'southAmericanIndianOther',
    displayValue: 'South American Indian (Other)',

    order: 403,
  },
  southNdebele: { code: 'southNdebele', displayValue: 'South Ndebele', order: 405 },
  southernSami: { code: 'southernSami', displayValue: 'Southern Sami', order: 404 },
  spanish: { code: 'spanish', displayValue: 'Spanish', order: 7 },
  sukuma: { code: 'sukuma', displayValue: 'Sukuma', order: 407 },
  sumerian: { code: 'sumerian', displayValue: 'Sumerian', order: 408 },
  sundanese: { code: 'sundanese', displayValue: 'Sundanese', order: 409 },
  susu: { code: 'susu', displayValue: 'Susu', order: 410 },
  swahili: { code: 'swahili', displayValue: 'Swahili', order: 411 },
  swati: { code: 'swati', displayValue: 'Swati', order: 412 },
  swedish: { code: 'swedish', displayValue: 'Swedish', order: 413 },
  syriac: { code: 'syriac', displayValue: 'Syriac', order: 414 },
  tagalog: { code: 'tagalog', displayValue: 'Tagalog', order: 13 },
  tahitian: { code: 'tahitian', displayValue: 'Tahitian', order: 416 },
  taiOther: { code: 'taiOther', displayValue: 'Tai (Other)', order: 417 },
  tajik: { code: 'tajik', displayValue: 'Tajik', order: 418 },
  tamashek: { code: 'tamashek', displayValue: 'Tamashek', order: 419 },
  tamil: { code: 'tamil', displayValue: 'Tamil', order: 420 },
  tatar: { code: 'tatar', displayValue: 'Tatar', order: 421 },
  telugu: { code: 'telugu', displayValue: 'Telugu', order: 422 },
  tereno: { code: 'tereno', displayValue: 'Tereno', order: 423 },
  tetum: { code: 'tetum', displayValue: 'Tetum', order: 424 },
  thai: { code: 'thai', displayValue: 'Thai', order: 425 },
  tibetan: { code: 'tibetan', displayValue: 'Tibetan', order: 426 },
  tigre: { code: 'tigre', displayValue: 'Tigre', order: 427 },
  tigrinya: { code: 'tigrinya', displayValue: 'Tigrinya', order: 428 },
  timne: { code: 'timne', displayValue: 'Timne', order: 429 },
  tiv: { code: 'tiv', displayValue: 'Tiv', order: 430 },
  tlingit: { code: 'tlingit', displayValue: 'Tlingit', order: 431 },
  tokPisin: { code: 'tokPisin', displayValue: 'Tok Pisin', order: 432 },
  tokelau: { code: 'tokelau', displayValue: 'Tokelau', order: 433 },
  tongaNyasa: { code: 'tongaNyasa', displayValue: 'Tonga (Nyasa)', order: 434 },
  tongaIslands: { code: 'tongaIslands', displayValue: 'Tonga (Tonga Islands)', order: 435 },
  tsimshian: { code: 'tsimshian', displayValue: 'Tsimshian', order: 436 },
  tsonga: { code: 'tsonga', displayValue: 'Tsonga', order: 437 },
  tswana: { code: 'tswana', displayValue: 'Tswana', order: 438 },
  tumbuka: { code: 'tumbuka', displayValue: 'Tumbuka', order: 439 },
  tupiLanguages: { code: 'tupiLanguages', displayValue: 'Tupi languages', order: 440 },
  turkish: { code: 'turkish', displayValue: 'Turkish', order: 441 },
  turkishOttoman1500To1928: {
    code: 'turkishOttoman1500To1928',
    displayValue: 'Turkish, Ottoman (1500-1928)',

    order: 442,
  },
  turkmen: { code: 'turkmen', displayValue: 'Turkmen', order: 443 },
  tuvalu: { code: 'tuvalu', displayValue: 'Tuvalu', order: 444 },
  tuvinian: { code: 'tuvinian', displayValue: 'Tuvinian', order: 445 },
  twi: { code: 'twi', displayValue: 'Twi', order: 446 },
  ugaritic: { code: 'ugaritic', displayValue: 'Ugaritic', order: 447 },
  uighur: { code: 'uighur', displayValue: 'Uighur', order: 448 },
  ukrainian: { code: 'ukrainian', displayValue: 'Ukrainian', order: 10 },
  umbundu: { code: 'umbundu', displayValue: 'Umbundu', order: 450 },
  urdu: { code: 'urdu', displayValue: 'Urdu', order: 452 },
  uzbek: { code: 'uzbek', displayValue: 'Uzbek', order: 453 },
  vai: { code: 'vai', displayValue: 'Vai', order: 454 },
  venda: { code: 'venda', displayValue: 'Venda', order: 455 },
  vietnamese: { code: 'vietnamese', displayValue: 'Vietnamese', order: 15 },
  volapük: { code: 'volapük', displayValue: 'Volapük', order: 457 },
  votic: { code: 'votic', displayValue: 'Votic', order: 458 },
  wakashanLanguages: { code: 'wakashanLanguages', displayValue: 'Wakashan languages', order: 459 },
  walamo: { code: 'walamo', displayValue: 'Walamo', order: 460 },
  walloon: { code: 'walloon', displayValue: 'Walloon', order: 461 },
  waray: { code: 'waray', displayValue: 'Waray', order: 462 },
  washo: { code: 'washo', displayValue: 'Washo', order: 463 },
  welsh: { code: 'welsh', displayValue: 'Welsh', order: 464 },
  wolof: { code: 'wolof', displayValue: 'Wolof', order: 465 },
  xhosa: { code: 'xhosa', displayValue: 'Xhosa', order: 466 },
  yakut: { code: 'yakut', displayValue: 'Yakut', order: 467 },
  yao: { code: 'yao', displayValue: 'Yao', order: 468 },
  yapese: { code: 'yapese', displayValue: 'Yapese', order: 469 },
  yiddish: { code: 'yiddish', displayValue: 'Yiddish', order: 470 },
  yoruba: { code: 'yoruba', displayValue: 'Yoruba', order: 471 },
  yupikLanguages: { code: 'yupikLanguages', displayValue: 'Yupik languages', order: 472 },
  zande: { code: 'zande', displayValue: 'Zande', order: 473 },
  zapotec: { code: 'zapotec', displayValue: 'Zapotec', order: 474 },
  zenaga: { code: 'zenaga', displayValue: 'Zenaga', order: 475 },
  zhuang: { code: 'zhuang', displayValue: 'Zhuang', order: 476 },
  zulu: { code: 'zulu', displayValue: 'Zulu', order: 477 },
  zuni: { code: 'zuni', displayValue: 'Zuni', order: 478 },
};
