/* eslint-disable @typescript-eslint/ban-types */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { Address, CanadaPostAddress, CanadaPostAddressDetails } from '@common/entities';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { StringValidator } from '../shared/validators/string.validator';
import { ValidateABMuni } from '../ab-city-autocomplete/ab-muni.validator';
import { AddressValidationService } from '../shared/services/address/address-validation.service';
import { AppConfigService } from '../ui-shared-components.services';

@Component({
  selector: 'common-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: AddressComponent, multi: true },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: NG_VALIDATORS, useExisting: AddressComponent, multi: true },
  ],
})
export class AddressComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private defaultProvince = 'Alberta';
  private allProvinces = {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  };

  get provinces() {
    return Object.values(this.allProvinces).filter(
      (p) => !this.filterProvinces || this.filterProvinces.indexOf(p) >= 0
    );
  }
  addressLookupControl = this.formBuilder.control('');
  formGroup = this.formBuilder.group(
    {
      canadaPostId: [null],
      streetAddress: [''],
      suiteNumber: [''],
      city: [''],
      province: [{ value: this.defaultProvince, disabled: true }],
      postalCode: [''],
    },
    { updateOn: 'blur' }
  );
  destroy$ = new Subject<void>();

  @Input() showStreetAddressPlaceholder = false;
  @Input() dataCyPrefix = '';
  @Input() hideProvince = false;
  @Input() hideOptionalLabel = false;
  @Input() postalCodeFormat = '';

  /**
   * Indicates whether a separate address lookup control should be displayed.
   * It is recommended to use either allowLookup or useAddressValidationService (not both at the same time)
   */
  @Input() allowLookup: boolean;

  /**
   * Indicates whether to use the address validation service, currently triggered by the street address input.
   * If true, the address lookup will be triggered when the street address is changed.
   * For the default lookup service:
   * Backend NestJs Service:
   *  Scenario 1: Use the default validation service:
   *    - Add the AddressModule into the imports array into the main project module and call forRoot method with config keys to use, such as:
   *      AddressModule.forRoot({
   *        authClient: 'SCSS_IES_IDP_CLIENT',
   *        authSecret: 'SCSS_IES_IDP_SECRET',
   *        authUrl: 'ABGOV_IDP_URL',
   *        validationUrl: 'ADDRESS_VALIDATION_URL'
   *    }),
   *    - Add a resolver that injects the AddressValidationService and calls the findAddress() method.
   *    - Add a resolver that injects the AddressValidationService and calls the findAddressDetails() method.
   *
   *  Scenario 2: Create custom address validation service:
   *   - Create a custom address validation service that implements the IAddressValidationService interface.
   *   - Create resolver that injects the custom address validation service and calls the findAddress() method.
   *  -  Create resolver that injects the custom address validation service and calls the findAddressDetails() method.
   *
   *  Environment Overrides:
   *      ADDRESS_VALIDATION_MAX_SUGGESTIONS (default is 5). Determines the maximum number of suggestions to return.
   *
   * Frontend Angular Service:
   *  Config Overrides (config.json):
   *    ADDRESS_VALIDATION_MIN_CHARACTER_LOOKUP (default is 5).  Determines the minimum number of characters before a lookup is triggered.
   *    ADDRESS_VALIDATION_MIN_LOOKUP_DELAY_MS (default is 400). Determines the minimum delay in milliseconds before a lookup is triggered.
   */
  @Input() useAddressValidationService = false;

  @Input() municipalities;
  @Input() suggestedAddresses: CanadaPostAddress[];
  private _hideLookupResult: boolean;
  @Input() set hideLookupResult(value: boolean) {
    this._hideLookupResult = value;
    this.hideLookupResultChange.emit(value);
  }
  get hideLookupResult() {
    return this._hideLookupResult;
  }
  @Output() hideLookupResultChange = new EventEmitter<boolean>();

  @Input() municipalityMode: 'contains' | 'startsWith' = 'startsWith';

  private _abMunicipalities = false;
  @Input()
  public get abMunicipalities() {
    return this._abMunicipalities;
  }
  public set abMunicipalities(value) {
    this._abMunicipalities = value;
    this.setCityValidators();
  }

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    value ? this.formGroup.markAllAsTouched() : this.formGroup.markAsUntouched();
  }

  _isRequired: boolean;
  @Input() set isRequired(value: boolean) {
    this._isRequired = value;
    if (value) {
      this.formGroup.controls.streetAddress.setValidators([Validators.required, StringValidator.notEmpty]);
      this.formGroup.controls.streetAddress.updateValueAndValidity();
      this.setCityValidators();
      this.formGroup.controls.postalCode.setValidators([Validators.required]);
      this.formGroup.controls.postalCode.updateValueAndValidity();
    } else {
      this.formGroup.controls.streetAddress.clearValidators();
      this.formGroup.controls.streetAddress.updateValueAndValidity();
      this.formGroup.controls.city.clearValidators();
      this.formGroup.controls.city.updateValueAndValidity();
      this.formGroup.controls.postalCode.clearValidators();
      this.formGroup.controls.postalCode.updateValueAndValidity();
    }
  }
  get isRequired() {
    return this._isRequired;
  }

  _enableProvince: boolean;
  @Input() set enableProvince(value: boolean) {
    this._enableProvince = value;
    value
      ? this.formGroup.controls.province.enable({ emitEvent: false })
      : this.formGroup.controls.province.disable({ emitEvent: false });
  }
  get enableProvince(): boolean {
    return this._enableProvince;
  }

  @Input() filterProvinces?: String[] = null;
  @Output() lookupAddress = new EventEmitter<string>();

  private streetAddressSubject = new Subject<string>();

  @Input() provinceTooltip: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private addressValidationService: AddressValidationService,
    private configService: AppConfigService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!value.province) {
        value.province = this.defaultProvince;
      }
      this.setCityValidators();
      if (value?.streetAddress) value.streetAddress = value.streetAddress.trim();
      if (value?.city) value.city = value.city.trim();
      if (value?.postalCode) value.postalCode = value.postalCode.trim();
      if (value?.suiteNumber) value.suiteNumber = value.suiteNumber.trim();
      value.canadaPostId = null;
      this.changed(value);
    });
    this.addStreetAddressInputChangeHandler();
  }

  private async addStreetAddressInputChangeHandler() {
    await this.configService.loadConfig();
    const lookupMinCharacters = parseInt(this.configService.getConfig().ADDRESS_VALIDATION_MIN_CHARACTER_LOOKUP) || 5;
    const lookupDelay = parseInt(this.configService.getConfig().ADDRESS_VALIDATION_MIN_LOOKUP_DELAY_MS) || 400;

    this.streetAddressSubject
      .pipe(debounceTime(lookupDelay), takeUntil(this.destroy$))
      .subscribe(async (streetAddress: string) => {
        try {
          if (streetAddress.length >= lookupMinCharacters) {
            this.suggestedAddresses = await this.addressValidationService.findAddress(
              streetAddress,
              this.enableProvince
            );
          } else {
            this.suggestedAddresses = [];
          }
        } catch (error) {
          // Clear the suggested addresses, user must manually enter the address
          this.suggestedAddresses = [];
        }
        this.cdr.detectChanges();
      });
  }

  async onStreetAddressInputChange(streetAddress: string) {
    this.streetAddressSubject.next(streetAddress);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validate() {
    return this.formGroup.valid ? null : { invalid: true };
  }

  writeValue(address: Address): void {
    if (!address)
      address = {
        streetAddress: '',
        suiteNumber: '',
        city: '',
        province: this.defaultProvince,
        postalCode: '',
      };
    else if (!address.province) {
      address.province = this.defaultProvince;
    }
    //if we are given a province that isn't in the list or if we don't allow province to be set, then default
    if (
      !this.enableProvince ||
      !Object.values(this.allProvinces).find((p) => p.toLocaleLowerCase() === address.province.toLocaleLowerCase())
    ) {
      address.province = this.defaultProvince;
    }

    address.streetAddress = address.streetAddress?.trim() ?? '';
    address.city = address.city?.trim() ?? '';
    address.postalCode = address.postalCode?.trim() ?? '';
    address.suiteNumber = address.suiteNumber?.trim() ?? '';

    const previousProvince = this.formGroup.value.province;
    this.formGroup.patchValue(address, { emitEvent: false });
    //if we've changed province reupdate the city validation
    if (previousProvince !== address.province) this.setCityValidators();
  }

  private changed;
  registerOnChange(fn) {
    this.changed = fn;
  }
  private touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });

    if (!isDisabled)
      this._enableProvince
        ? this.formGroup.controls.province.enable({ emitEvent: false })
        : this.formGroup.controls.province.disable({ emitEvent: false });
  }

  onSelectAddress(canadaPostAddress: CanadaPostAddress) {
    this.addressLookupControl.setValue(null);
    this.formGroup.patchValue(this.mapAddressToForm(canadaPostAddress));
  }

  async onStreetAddressSelected(canadaPostAddress: CanadaPostAddress) {
    try {
      this.formGroup.controls.streetAddress.setValue(canadaPostAddress.Text);
      const addressDetails: CanadaPostAddressDetails[] = await this.addressValidationService.findAddressDetails(
        canadaPostAddress.Id
      );

      this.formGroup.patchValue(this.mapAddressDetailToForm(addressDetails[0]));
    } catch (error) {
      // User must manually enter the address
    }
  }

  async onLookupAddress(searchTerm: string) {
    this.lookupAddress.emit(searchTerm);
  }
  onAddressClick(event) {
    this.hideLookupResult = true;
    event.stopPropagation();
  }
  private mapAddressToForm(canadaPostAddress: CanadaPostAddress): Address {
    const matchedAddress = canadaPostAddress.Text.match(/^([0-9]+)-(.*)$/);
    const descriptionArray = canadaPostAddress.Description.split(', ');
    return {
      canadaPostId: canadaPostAddress.Id,
      suiteNumber: matchedAddress ? matchedAddress[1] : null,
      streetAddress: matchedAddress ? matchedAddress[2] : canadaPostAddress.Text,
      city: descriptionArray.length ? descriptionArray[0] : null,
      province: descriptionArray.length > 1 ? this.allProvinces[descriptionArray[1]] : null,
      postalCode: descriptionArray.length > 2 ? descriptionArray[2].replace(' ', '') : null,
    };
  }

  private mapAddressDetailToForm(canadaPostAddress: CanadaPostAddressDetails): Address {
    const isStreetPopulated =
      canadaPostAddress.Street != '' && canadaPostAddress.Street != null && canadaPostAddress.Street != undefined;
    const streetAddress = isStreetPopulated
      ? canadaPostAddress.BuildingNumber + ' ' + canadaPostAddress.Street
      : canadaPostAddress.Line1;

    return {
      canadaPostId: canadaPostAddress.Id,
      suiteNumber: canadaPostAddress.SubBuilding,
      streetAddress,
      city: canadaPostAddress.City,
      province: this.allProvinces[canadaPostAddress.ProvinceCode] ?? null,
      postalCode: canadaPostAddress.PostalCode,
    };
  }

  private setCityValidators() {
    if (!this.isRequired) this.formGroup.controls.city.clearValidators();
    else {
      if (this.abMunicipalities && (!this.enableProvince || this.formGroup.value.province === this.defaultProvince)) {
        this.formGroup.controls.city.setValidators([
          Validators.required,
          StringValidator.notEmpty,
          ValidateABMuni(this.municipalities),
        ]);
      } else this.formGroup.controls.city.setValidators([Validators.required, StringValidator.notEmpty]);
    }
    this.formGroup.controls.city.updateValueAndValidity({ emitEvent: false });
  }
}
