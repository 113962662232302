export interface Lookup {
  code?: string;
  displayValue: string;
  htmlValue?: string;
  tips?: string;
}

export interface Location {
  key?: string;
  displayName?: string;
  code?: string;
  default?: boolean;
  subLocation?: LocationList;
  isCanada?: boolean;
  isAlberta?: boolean;
}

export interface LocationList {
  [displayName: string]: Location;
}
