import { Length } from 'class-validator';
import { Expose } from 'class-transformer';

/**
 * Information about a phone number
 */
export class  PhoneNumber {
  /**
   * Type of phone
   */
  @Expose()
  type: string;

  /**
   * The phone number
   */
  //@IsNumber()
  @Expose()
  @Length(10, 10) //exactly 10 digits
  phoneNumber: string;

  /**
   * Is this a primary number for contact
   */
  isPrimary?: boolean;
}
