export interface FbAppData {
  pid?: string;
  clientFirstName: string; //Deceased first name
  clientLastName: string;
  clientFullName: string;
  applicantRelationship: string;
  applicantFirstName: string;
  applicantLastName: string;
  applicantAddress1?: string;
  applicantCity?: string;
  applicantProvince?: string;
  applicantPostalCode?: string;
  applicantPhone?: string;
  applicantEmail?: string;
  invoiceDate?: string;
  receivedDate?: string; //required format YYYY-MM-DD
  doNumber?: string;
  clientProgram?: string; // valid values AISH, IS, LIA, OPG
  clientBenefit?: string;
  sinNumber?: string;
  fileNumber?: string; //Income support file number
  deathDate?: string; //required format YYYY-MM-DD
  funeralVendorLabel?: string;
  funeralVendorName?: string;
  funeralHomeAmount?: number;
  cemeteryVendorLabel?: string;
  cemeteryVendorName?: string;
  cemeteryAmount?: number;
  applicantAmount?: number;
  edbMessage?: string;
  docType?: string;
  exceptionalAmountFlag?: string;
}
