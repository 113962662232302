<div [formGroup]="formGroup">
  <section aria-labelledby="child-info-section-label" class="sub-form">
    <h2 id="child-info-section-label" data-cy="page-title">{{ fiChildInfoLabels.header | labelText }}</h2>

    <div class="form-control first-field">
      <common-form-control [label]="fiChildInfoLabels.firstName | labelText" [control]="formGroup.controls.firstName">
        <!--Blur and focus events were added as a workaround to prevent the browser from overwriting values with autofill-->
        <input
          type="text"
          data-cy="firstName"
          formControlName="firstName"
          autocomplete="off"
          [readonly]="isReadOnly"
          (blur)="toggleReadOnly()"
          (focus)="toggleReadOnly()"
        />
      </common-form-control>
    </div>
    <div class="form-control">
      <common-form-control
        [label]="fiChildInfoLabels.middleName | labelText"
        [isOptional]="true"
        [control]="formGroup.controls.middleName"
      >
        <input
          type="text"
          formControlName="middleName"
          data-cy="middleName"
          [readonly]="isReadOnly"
          (blur)="toggleReadOnly()"
          (focus)="toggleReadOnly()"
        />
      </common-form-control>
    </div>

    <div class="form-control">
      <common-form-control [label]="fiChildInfoLabels.lastName | labelText" [control]="formGroup.controls.lastName">
        <!--Blur and focus events were added as a workaround to prevent the browser from overwriting values with autofill-->
        <input
          type="text"
          formControlName="lastName"
          data-cy="lastName"
          [readonly]="isReadOnly"
          (blur)="toggleReadOnly()"
          (focus)="toggleReadOnly()"
        />
      </common-form-control>
    </div>

    <div class="form-control">
      <common-form-control
        [label]="fiChildInfoLabels.preferredName | labelText"
        [isOptional]="true"
        [control]="formGroup.controls.preferredName"
      >
        <input type="text" formControlName="preferredName" data-cy="preferredName" />
      </common-form-control>
    </div>

    <div class="form-control" role="group" aria-label="Birthdate">
      <common-form-control [label]="fiChildInfoLabels.birthdate | labelText" [control]="formGroup.controls.birthdate">
        <common-date-picker
          formControlName="birthdate"
          data-cy="birthdate"
          [iesMaxDate]="_dateMax"
          iesMinDateErrorMessage="Child cannot be older than 18"
          [iesMinDate]="_dateMin"
          required
        ></common-date-picker>
      </common-form-control>
    </div>

    <div class="form-control gender">
      <common-form-control [label]="fiChildInfoLabels.gender | labelText" [control]="formGroup.controls.gender">
        <mat-select
          id="gender"
          color="primary"
          [formControl]="formGroup.controls.gender"
          data-cy="gender"
          aria-label="gender"
          disableOptionCentering="true"
          placeholder="{{ selectOneText }}"
        >
          <mat-option disabled value="">{{ selectOneText }}</mat-option>
          <mat-option *ngFor="let item of genderList | keyvalue: unsort" [value]="item.key">
            {{ item.value['displayValue'] }}
          </mat-option>
        </mat-select>
      </common-form-control>
    </div>

    <div class="form-control">
      <common-fieldset-control [label]="fiChildInfoLabels.hasPrevApplication | labelText">
        <mat-radio-group [formControl]="formGroup.controls.hasPrevApplication" color="primary">
          <mat-radio-button
            data-cy="hasPrevApplication-yes"
            [value]="true"
            (change)="onHasPrevApplicationChange($event.value)"
          >
            Yes
          </mat-radio-button>
          <mat-radio-button
            data-cy="hasPrevApplication-no"
            [value]="false"
            (change)="onHasPrevApplicationChange($event.value)"
          >
            No
          </mat-radio-button>
        </mat-radio-group>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.hasPrevApplication?.errors && formGroup?.controls?.hasPrevApplication?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>

    <div class="form-control sub-question" *ngIf="formGroup.controls.hasPrevApplication.value">
      <common-fieldset-control [label]="fiChildInfoLabels.hasPrevClosedWithin90 | labelText">
        <mat-radio-group [formControl]="formGroup.controls.hasPrevClosedWithin90" color="primary">
          <mat-radio-button
            *ngFor="let item of customRadioOptions | keyvalue: unsort"
            [value]="item.key"
            [attr.data-cy]="'hasPrevClosedWithin90-' + item.key"
          >
            {{ item.value['displayValue'] }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          class="error-text"
          *ngIf="
            formGroup?.controls?.hasPrevClosedWithin90?.errors && formGroup?.controls?.hasPrevClosedWithin90?.touched
          "
        >
          This field is required.
        </div>
      </common-fieldset-control>

      <div class="callout mt-16">
        <goa-callout
          *ngIf="
            formGroup?.controls?.hasPrevClosedWithin90?.value === 'yes' ||
            formGroup?.controls?.hasPrevClosedWithin90?.value === 'unsure'
          "
          type="important"
        >
          Please note that if your child’s file was closed within the last 90 days, there’s no need to submit a new FSCD
          application. Please call your local FSCD office for more information.
        </goa-callout>
      </div>
    </div>

    <div class="form-control">
      <common-form-control
        [label]="fiChildInfoLabels.isChildCitizenOrPermanentResident | labelText"
        [control]="formGroup.controls.citizenImmigrationStatus"
      >
        <mat-select
          id="citizenImmigrationStatus"
          color="primary"
          data-cy="citizenImmigrationStatus"
          [formControl]="formGroup.controls.citizenImmigrationStatus"
          aria-label="citizenship/immigration status"
          placeholder="{{ selectOneText }}"
          disableOptionCentering="true"
          (selectionChange)="onCitizenImmigrationStatusChange($event.value)"
        >
          <mat-option disabled value="">{{ selectOneText }}</mat-option>
          <mat-option *ngFor="let item of legalStatusList | keyvalue: unsort" [value]="item.value['code']">
            {{ item.value['displayValue'] }}
          </mat-option>
        </mat-select>
      </common-form-control>
    </div>
  </section>
  <div class="width-80 form-control" *ngIf="formGroup.controls.citizenImmigrationStatus.value">
    <common-document-upload
      label="Upload supporting document(s)"
      [parentId]="applicationId"
      [showUploadRemaining]="false"
      [groupName]="'identityDocsUploadCntl'"
      [documentsUploaded]="identityDocuments"
      [documentCategories]="identityDocumentsNeeded"
      (fileUploaded)="onIdentityFileUploaded($event)"
      (removeFile)="onRemoveIdentityFile($event)"
      tooltipMessage="Display file in new window"
      tooltipPosition="right"
      ariaLabelForPreviewIcon="Display document"
      (uploadStarted)="uploadStarted()"
      (uploadCompleted)="uploadCompleted()"
    >
    </common-document-upload>
  </div>

  <div class="sub-form">
    <div class="label">{{ fiChildInfoLabels.residentialAddress | labelText }}</div>
    <div class="form-control" data-cy="residentialAddress">
      <common-address
        dataCyPrefix="residential"
        formControlName="residentialAddress"
        [isTouched]="formGroup?.get('residentialAddress')?.touched"
        [isRequired]="true"
        [useAddressValidationService]="true"
        postalCodeFormat="AB"
        [showStreetAddressPlaceholder]="true"
        provinceTooltip="Residential address must be in Alberta."
      ></common-address>
    </div>
    <div class="form-control">
      <common-fieldset-control [label]="fiChildInfoLabels.hasSameMailingAddress | labelText">
        <mat-radio-group
          [formControl]="formGroup.controls.hasSameMailingAddress"
          (change)="onSameMailingAddressChange($event.value)"
          color="primary"
        >
          <mat-radio-button data-cy="hasSameMailingAddress-yes" [value]="true">Yes</mat-radio-button>
          <mat-radio-button data-cy="hasSameMailingAddress-no" [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div
          class="error-text"
          *ngIf="
            formGroup?.controls?.hasSameMailingAddress?.errors && formGroup?.controls?.hasSameMailingAddress?.touched
          "
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>
    <div
      class="form-control sub-question"
      *ngIf="formGroup.controls.hasSameMailingAddress.value === false"
      data-cy="mailingAddress"
    >
      <common-address
        dataCyPrefix="mailing"
        formControlName="mailingAddress"
        [isTouched]="formGroup?.get('mailingAddress')?.touched"
        [isRequired]="true"
        [useAddressValidationService]="true"
        postalCodeFormat="AB"
        [showStreetAddressPlaceholder]="true"
        provinceTooltip="Mailing address must be in Alberta."
      ></common-address>
    </div>

    <div class="form-control">
      <common-form-control
        [label]="fiChildInfoLabels.livingArrangements | labelText"
        [control]="formGroup.controls.livingArrangements"
      >
        <mat-select
          id="living-arrangement"
          data-cy="livingArrangement"
          color="primary"
          [formControl]="formGroup.controls.livingArrangements"
          aria-label="Child’s living arrangements"
          placeholder="{{ selectOneText }}"
          disableOptionCentering="true"
          data-cy="livingArrangements"
        >
          <mat-option disabled value="">{{ selectOneText }}</mat-option>
          <mat-option *ngFor="let item of livingArrangementList | keyvalue: unsort" [value]="item.value['code']">
            {{ item.value['displayValue'] }}
          </mat-option>
        </mat-select>
      </common-form-control>
    </div>

    <div class="form-control">
      <common-fieldset-control [label]="fiChildInfoLabels.hasConfirmedDiagnosis | labelText">
        <mat-radio-group
          [formControl]="formGroup.controls.hasConfirmedDiagnosis"
          (change)="onHasConfirmedDiagnosisChange($event.value)"
          color="primary"
        >
          <mat-radio-button data-cy="hasConfirmedDiagnosis-yes" [value]="true">Yes</mat-radio-button>
          <mat-radio-button data-cy="hasConfirmedDiagnosis-no" [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div
          class="error-text"
          *ngIf="
            formGroup?.controls?.hasConfirmedDiagnosis?.errors && formGroup?.controls?.hasConfirmedDiagnosis?.touched
          "
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>

    <div class="form-control sub-question" *ngIf="formGroup.controls.hasConfirmedDiagnosis.value">
      <common-fieldset-control [control]="formGroup.controls.confirmedDiagnosis">
        <label class="label" for="confirmedDiagnosis"
          ><span>{{ fiChildInfoLabels.confirmedDiagnosis | labelText }}</span></label
        >
        <textarea
          formControlName="confirmedDiagnosis"
          id="confirmedDiagnosis"
          maxlength="250"
          rows="3"
          data-cy="confirmedDiagnosis"
          class="textarea"
          autosize
        ></textarea>
        <div class="textarea-bt">
          <span
            class="error-text error-align"
            *ngIf="formGroup?.controls?.confirmedDiagnosis?.errors && formGroup?.controls?.confirmedDiagnosis?.touched"
          >
            This field is required.
          </span>
          <span class="character-count">{{ characterCountConfirmedDiagnosis }}/250</span>
        </div>
      </common-fieldset-control>
    </div>

    <div class="form-control">
      <common-fieldset-control [label]="fiChildInfoLabels.isAwaitingDiagnosis | labelText">
        <mat-radio-group
          [formControl]="formGroup.controls.isAwaitingDiagnosis"
          (change)="onIsAwaitingDiagnosisChange($event.value)"
          color="primary"
        >
          <mat-radio-button data-cy="isAwaitingDiagnosis-yes" [value]="true">Yes</mat-radio-button>
          <mat-radio-button data-cy="isAwaitingDiagnosis-no" [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div
          class="error-text"
          *ngIf="formGroup?.controls?.isAwaitingDiagnosis?.errors && formGroup?.controls?.isAwaitingDiagnosis?.touched"
        >
          This field is required.
        </div>
      </common-fieldset-control>
    </div>

    <div class="form-control sub-question" *ngIf="formGroup.controls.isAwaitingDiagnosis.value">
      <common-fieldset-control [control]="formGroup.controls.waitingDiagnosis">
        <label class="label" for="diagnosisExplored"
          ><span>{{ fiChildInfoLabels.diagnosisExplored | labelText }}</span></label
        >
        <textarea
          formControlName="waitingDiagnosis"
          id="waitingDiagnosis"
          maxlength="250"
          rows="3"
          data-cy="waitingDiagnosis"
          class="textarea"
          autosize
        ></textarea>
        <div class="textarea-bt">
          <span
            class="error-text error-align"
            *ngIf="formGroup?.controls?.waitingDiagnosis?.errors && formGroup?.controls?.waitingDiagnosis?.touched"
          >
            This field is required.
          </span>
          <span class="character-count">{{ characterCountAwaitingDiagnosis }}/250</span>
        </div>
      </common-fieldset-control>
    </div>

    <div
      class="form-control"
      *ngIf="
        formGroup.controls.hasConfirmedDiagnosis.value === false &&
        formGroup.controls.isAwaitingDiagnosis.value === false
      "
    >
      <goa-callout type="emergency" heading="Emergency callout">
        Please note, in order for your application to be considered for FSCD eligibility, your child must have a
        confirmed diagnosis or be waiting to receive a diagnosis. Please review your responses.
      </goa-callout>
    </div>
  </div>

  <div class="width-80 form-control">
    <common-document-upload
      label="Upload child’s medical document(s)"
      [parentId]="applicationId"
      [showUploadRemaining]="false"
      [groupName]="'medicalDocsUploadCntl'"
      [documentsUploaded]="medicalDocuments"
      [categoryLabel]="'Copy of'"
      [documentCategories]="medicalDocumentsNeeded"
      (fileUploaded)="onMedicalFileUploaded($event)"
      (removeFile)="onRemoveMedicalFile($event)"
      tooltipMessage="Display file in new window"
      tooltipPosition="right"
      ariaLabelForPreviewIcon="Display document"
      (uploadStarted)="uploadStarted()"
      (uploadCompleted)="uploadCompleted()"
    >
    </common-document-upload>
  </div>

  <div class="width-80 form-control">
    <common-document-upload
      label="Upload child’s additional document(s)"
      [parentId]="applicationId"
      [showUploadRemaining]="false"
      [groupName]="'additionalDocsUploadCntl'"
      [documentsUploaded]="additionalDocuments"
      [categoryLabel]="'Copy of'"
      [documentCategories]="additionalDocumentsNeeded"
      (fileUploaded)="onAdditionalFileUploaded($event)"
      (removeFile)="onRemoveAdditionalFile($event)"
      tooltipMessage="Display file in new window"
      tooltipPosition="right"
      ariaLabelForPreviewIcon="Display document"
      (uploadStarted)="uploadStarted()"
      (uploadCompleted)="uploadCompleted()"
    >
    </common-document-upload>
  </div>
</div>
