import { IntakeLabelCollection } from '../../entities/intake-label.interface';

export const AishContactsLabels: IntakeLabelCollection = {
  typeOfContact: {
    label: 'Who would you like to name as your contact?',
  },
  individualType: {
    label: 'Individual',
  },
  organizationType: {
    label: 'Organization',
  },
  firstName: {
    label: 'First name',
  },
  lastName: {
    label: 'Last name',
  },
  preferredName: {
    label: 'Preferred name if different',
  },
  relationship: {
    label: 'Relationship',
  },
  phoneNumber: {
    label: 'Phone number',
  },
  email: {
    label: 'Email address',
  },
  organizationName: {
    label: 'Organization name',
  },

  firstNameOrg: {
    label: 'First name of the contact',
  },
  lastNameOrg: {
    label: 'Last name of the contact',
  },
  roleInOrg: {
    label: 'Role in the organization',
  },
};
