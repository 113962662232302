import { UploadLookup } from './upload-lookup.interface';
import { DocumentsUpload } from './documents-upload.interface';

const ResidencyDocs = [
  "Current driver's license",
  'Non-driver government issued identification card',
  'Rent receipt',
  'Utility bill',
  'Letter from facility/institution',
  'Letter from shelter',
  'Letter from community agency',
];

const IdentificationDocs = [
  "Driver's license",
  'Non-driver government issued identification card',
  'Birth, marriage or baptismal certificate',
  'Employee or student card',
  'Band registry number, status card, or Metis card',
  'Alberta Personal Health Card',
  'Social Insurance Number',
];
const IdentificationDocsParter = [...IdentificationDocs, 'Statutory declaration'];

const ResidencyDocsPartner = [...ResidencyDocs, 'Statutory declaration'];

export const AishDocumentCategories: { [name: string]: DocumentsUpload } = {
  personalInformation: {
    residency: {
      displayValue: 'Where you live',
      documentTypes: [...ResidencyDocs],
      order: 1,
    },
    residencyPartner: {
      displayValue: 'Where you live',
      documentTypes: [...ResidencyDocsPartner],
      order: 1,
    },
    permResidency: {
      displayValue: 'Permanent Residency',
      documentTypes: ['Confirmation of permanent residence ', 'Permanent resident card', 'Record of landing'],
      categoryTitleOverride:
        'Proof of permanent residence (include an image of both the front and back of the residency card)',
    },
    identification: {
      displayValue: 'Identification',
      documentTypes: [...IdentificationDocs],
    },
    identificationPartner: {
      displayValue: 'Identification',
      documentTypes: [...IdentificationDocsParter],
    },
  },
  dependents: {
    identification: {
      displayValue: 'Identification',
      documentTypes: [
        "Driver's license",
        'Non-driver identification card',
        'Birth, marriage or baptismal certificate',
        'Employee or student card',
        'Band registry number, status card, or Metis card',
        'Alberta Personal Health Card',
        'Social Insurance Number',
      ],
      order: 0,
    },
    highSchoolDeclaration: {
      displayValue: 'High School Declaration',
      documentTypes: ['High School Declaration'],
      order: 1,
    },
  },
  medical: {
    medicalReport: {
      displayValue: 'Medical report',
      documentTypes: ['AISH Medical report', 'Other medical document'],
    },
    medicalLetter: {
      displayValue: 'Medical letter',
      documentTypes: ['Medical letter', 'Other medical document'],
    },
    otherMedicalDocument: {
      displayValue: 'Other medical document',
      documentTypes: ['Other medical document'],
    },
  },

  consent: {
    consentDocument: {
      displayValue: 'Consent and declaration',
      documentTypes: ['Consent document'],
      categoryTitleOverride: 'Consent and Declaration',
      hideDocumentTypeLabel: true,
    },
  },

  spousePartner: {
    identification: {
      displayValue: 'Identification',
      documentTypes: [
        "Driver's license",
        'Non-driver government issued identification card',
        'Birth, marriage or baptismal certificate',
        'Employee or student card',
        'Band registry number, status card, or Metis card',
        'Alberta Personal Health Card',
        'Social Insurance Number',
      ],
    },
  },
  trusteeAttorney: {
    identification: {
      displayValue: 'Trustee/Attorney',
      documentTypes: ['Trustee/Attorney Document'],
    },
  },
};
