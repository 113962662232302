import { DIMGJob } from './dimg-job.interface';

export interface AddToQueueResult {
  applicationId: string;
  queueName: string;
  processorName: string;
  jobId: number;
  source: string;
  jobData: DIMGJob;
  submittedDate: Date;
}
