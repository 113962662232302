import { Lookup } from './lookup.interface';

export class CopyOrMove {
  static readonly Copy: Lookup = { displayValue: 'Copy', code: 'Copy' };
  static readonly Move: Lookup = { displayValue: 'Move', code: 'Move' };
}

export const CopyOrMoveOptions = {};
for (const [key, value] of Object.entries(CopyOrMove)) {
  CopyOrMoveOptions[key] = value;
}
