import { IntakeLabelCollection } from '../../entities/intake-label.interface';
import { MinistryList } from '../../constants/ministry-list';

export const AishConsentLabels: IntakeLabelCollection = {
  agreePrivacy: {
    label: 'I have read and agree to the Privacy Notice.',
  },
  agreePrivacyContent: {
    label: `The personal information you provide is being collected to determine your eligibility for social-based supports and benefits offered by the Government of Alberta under Alberta Supports. If you choose to apply, the personal information you provide will then be used and disclosed in the application process, ongoing eligibility verification, and for delivery of those programs, benefits or services offered by the Government of Alberta through Alberta Supports.<br /><br />
    The personal information provided to Alberta Supports is collected, used and disclosed under the authority of sections 33 to 40 of the Freedom of Information and Protection of Privacy Act and various statutes establishing the programs included in Alberta Supports. To see the list of the programs, including the legislation authorizing each program, you can click <a href="https://open.alberta.ca/publications/authorizing-legislation-for-alberta-supports-programs-and-services" target="_blank">Authorizing Legislation</a>.<br /><br />
    If you have questions about the collection or use of your personal information, please call the Alberta Supports Contact Centre at <a href="tel:780-644-9992">780-644-9992</a> in Edmonton or toll free at <a href="tel:1-877-644-9992">1-877-644-9992</a>.`,
  },
  agreeAishConsent: {
    label: 'I have read and agree to the AISH consent above.',
  },
  agreeAishConsentContent: {
    label:
      'I give my permission to any person, agency, organization, institution or other source to give the AISH program and/or AISH contracted services any information about my household situation, education and training, employment, and finances AISH asks for to decide if I am eligible for AISH. I understand I may withdraw my consent, in writing, at any time.',
  },
  agreeCraConsent: {
    label: 'I have read and agree to the Canada Revenue Agency consent above.',
  },
  agreeCraConsentContent: {
    label: `I authorize Canada Revenue Agency to release information required from my tax file to the Alberta Ministry of ${MinistryList['scss']?.displayValue}. The information will be relevant to and used solely for the purpose of determining and verifying my eligibility, or the eligibility of my co-habiting partner, for benefits under the Assured Income for the Severely Handicapped Act (c. A-45.1, 2006), and the general administration and enforcement of the benefit programs. This authorization is valid for the taxation year prior to the year of signature of this consent, the current taxation year, and each subsequent consecutive taxation year for which assistance is requested. I understand that if I wish to withdraw this consent, I may do so in writing to the Alberta Ministry of ${MinistryList['scss']?.displayValue}.`,
  },
  agreeDeclaration: {
    label: 'I have read and agree to the Declaration above.',
  },
  agreeDeclarationContent: {
    label: `
        I declare that the information I am giving about me, is true and complete and I understand that hiding information or giving false or incomplete information on purpose is an offence that could result in criminal charges.
    `,
  },
};
