import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CssRoles } from '@common/constants';
import { ValidationIssue } from '@common/entities';
import { NavigationFragments, ValidationType } from '@common/enums';
import { AppConfigService, ContextProvider, FileService, GoogleAnalyticsService } from '@common/ui/shared-components';
import { Utilities, ValidationUtils } from '@common/utils';
import { FiReview, FiStep, FscdIntakeApplication, FscdIntakeApplicationErrors } from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, lastValueFrom, of, take } from 'rxjs';
import { FiGraphqlService } from '../../services/fi-graphql.service';
import { PdfGenerationService } from '../../services/pdf-generation.service';
import { CHILD_INFO, PARENTAL_INFO, REVIEW, SERVICES } from '../application.steps';
import { BaseSaveComponent } from '../base-save/base-save.component';
import { DocumentIterator } from './document-iterator';

@Component({
  selector: 'fi-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewPageComponent extends BaseSaveComponent implements OnInit, OnDestroy, AfterContentChecked {
  steps: FiStep[] = [
    { ...CHILD_INFO, statusMessage: 'Completed' },
    { ...PARENTAL_INFO, statusMessage: 'Completed' },
    { ...SERVICES, statusMessage: 'Completed' },
    { ...REVIEW, statusMessage: 'Completed' },
  ];
  hasIssues = false;
  isConsentProvided = false;
  private destroy$ = new Subject<void>();
  application: FscdIntakeApplication;
  reviewFormValue;
  applicationErrors: FscdIntakeApplicationErrors;
  review$: Observable<FiReview>;
  hasErrors$: Observable<boolean>;
  loading = true;
  isSubmitted: boolean;

  constructor(
    apollo: Apollo,
    toasterService: ToastrService,
    private router: Router,
    protected route: ActivatedRoute,
    fileService: FileService,
    authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    contextProvider: ContextProvider,
    private graphqlService: FiGraphqlService,
    private pdfGeneratorService: PdfGenerationService,
    private analyticsService: GoogleAnalyticsService,
    private configService: AppConfigService = null
  ) {
    super(apollo, fileService, authenticationService, contextProvider, graphqlService, toasterService);
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.loadReview();
  }

  async loadReview() {
    const { selectedApplicationId } = await this.graphqlService.getSelectedApplication();
    this.applicationId = selectedApplicationId;

    if (!this.applicationId) {
      return;
    }
    const application = await lastValueFrom(this.graphqlService.getFullApplication(this.applicationId).pipe(take(1)));
    if (application?.isSubmitted === true) {
      this.router.navigate(['application', this.applicationId, this.steps[0].url]);
      return false;
    }

    this.applicationInfo = application;
    const applicationErrors = this.collectAllErrors(application);
    this.applicationErrors = applicationErrors;
    this.setStepStatuses(applicationErrors);
    this.setVisibleSteps(application);

    const updatedApplication = {
      id: this.applicationId,
      applicationErrors: applicationErrors,
    } as FscdIntakeApplication;

    this.hasIssues = this.checkIfApplicationHasIssues(applicationErrors);
    await lastValueFrom(this.graphqlService.updateApplication(updatedApplication));

    this.review$ = of(
      application?.review ? (Utilities.removeTypeNameFromObject(application.review) as FiReview) : new FiReview()
    );
    this.hasErrors$ = of(application?.applicationErrors?.reviewErrors?.length > 0 || false);
    this.loading = false;

    this.cdr.detectChanges();
  }

  private checkIfApplicationHasIssues(applicationErrors: FscdIntakeApplicationErrors): boolean {
    let hasIssues = false;
    for (const key in applicationErrors) {
      if (applicationErrors[key].length > 0) {
        hasIssues = true;
      }
    }
    return hasIssues;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save(doSubmit = false) {
    super.save();
    if (doSubmit === true) {
      this.loading = true;
      this.applicationInfo.submittedOn = new Date();
      this.applicationInfo.isUploaded = false;
      this.cleanPhoneNumbers(this.applicationInfo);
      try {
        // get application number
        const applicationWithNumber = await lastValueFrom(
          this.graphqlService.assignApplicationNumber(this.applicationInfo)
        );
        this.applicationInfo.applicationNumber = applicationWithNumber.applicationNumber;
        await this.uploadApplicationPDF();
        const documentCount = new DocumentIterator(this.applicationInfo).iterateNames()?.length;
        this.applicationInfo.documentCount = documentCount;

        this.applicationInfo.isSubmitted = true;
        const submitResponse = await lastValueFrom(this.graphqlService.updateApplication(this.applicationInfo));
        if (submitResponse.isSubmitted) {
          this.graphqlService.updateSelectedApplication(this.applicationId);
          this.loading = false;
          this.sendGoogleAnalyticsEvent();
          return true;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.displayStaticToastError('There was a problem submitting the application.');
        return false;
      }
    } else {
      return false;
    }
  }

  cleanPhoneNumbers(application: FscdIntakeApplication) {
    let phoneNumbers = application.parent?.phoneNumbers;
    if (phoneNumbers && phoneNumbers.length > 0) {
      phoneNumbers = phoneNumbers.filter((data) => Utilities.hasValue(data.phoneNumber));
      application.parent.phoneNumbers = [...phoneNumbers];
    }
  }

  private async uploadApplicationPDF() {
    try {
      const uploadedPdf = await this.pdfGeneratorService.uploadPdf(this.applicationInfo);
      this.applicationInfo.outputPdfUrl = uploadedPdf.azureUrl;
      this.applicationInfo.outputPdfFileName = uploadedPdf.key;
    } catch (error) {
      throw Error();
    }
  }

  private sendGoogleAnalyticsEvent() {
    if (this.configService.getConfig().GOOGLE_MEASUREMENT_ID) {
      try {
        const inTesterRole = this.profile?.roles?.includes(CssRoles.FSCDITester.code) ?? false;
        if (!inTesterRole) {
          // send event to google analytics only if the user is NOT in the tester role.
          this.analyticsService.event('submit', { event_category: 'Submit' });
        }
      } catch (error) {
        //do not throw
        console.error(error);
      }
    }
  }

  setStepStatuses(applicationErrors: FscdIntakeApplicationErrors) {
    this.steps.find((s) => s.code === CHILD_INFO.code).statusMessage = this.getStatusMessage(
      applicationErrors.childErrors
    );
    this.steps.find((s) => s.code === PARENTAL_INFO.code).statusMessage = this.getStatusMessage(
      applicationErrors.parentGuardianErrors
    );
    this.steps.find((s) => s.code === SERVICES.code).statusMessage = this.getStatusMessage(
      applicationErrors.servicesErrors
    );
    this.steps.find((s) => s.code === REVIEW.code).statusMessage = this.getStatusMessage(
      applicationErrors.reviewErrors
    );

    this.steps.find((s) => s.code === CHILD_INFO.code).hasError = applicationErrors.childErrors?.length > 0;
    this.steps.find((s) => s.code === PARENTAL_INFO.code).hasError = applicationErrors.parentGuardianErrors?.length > 0;
    this.steps.find((s) => s.code === SERVICES.code).hasError = applicationErrors.servicesErrors?.length > 0;
    this.steps.find((s) => s.code === REVIEW.code).hasError = applicationErrors.reviewErrors?.length > 0;

    // set step state to completed if not visited and has no errors
    this.steps
      .filter((step) => step.active)
      .forEach((step) => {
        if (step.hasError) {
          step.state = 'error';
        } else {
          step.state = 'done';
        }
      });
  }

  getStatusMessage(errors: ValidationIssue[]) {
    let message = 'Complete';

    if (errors?.length > 0) {
      const documentIssues = errors.filter((e) => e.type === ValidationType.Document);
      const otherIssues = errors.filter((e) => e.type === ValidationType.Other);

      const numDocIssues = documentIssues.reduce((sum, curr) => sum + curr.constraints?.length, 0);
      const numFieldIssues = otherIssues.length;

      const documentMessage =
        documentIssues.length > 0 ? `${numDocIssues} document${numDocIssues > 1 ? 's' : ''} missing` : '';
      const issuesMessage =
        otherIssues.length > 0
          ? `${numFieldIssues} field${numFieldIssues > 1 ? 's' : ''} require${
              numFieldIssues === 1 ? 's' : ''
            } attention`
          : '';

      const seperator = documentMessage.length > 0 && issuesMessage.length > 0 ? ', ' : '';

      message =
        otherIssues.length > 0
          ? `${documentMessage}${seperator} ${issuesMessage}`
          : `${documentMessage} require${numDocIssues === 1 ? 's' : ''} attention`;
    }

    return message;
  }

  setVisibleSteps(application) {
    this.steps.find((s) => s.code === REVIEW.code).active = false;
  }

  async onFormError() {
    this.displayStaticToastError('One or more Review fields are not filled in correctly.');
  }

  async onFormUpdated(formValue) {
    this.reviewFormValue = formValue;
    if (formValue.isConsentChecked) this.isConsentProvided = true;
    else this.isConsentProvided = false;
    this.cdr.detectChanges();
  }

  get hasIssuesInConsent() {
    return !this.isConsentProvided;
  }

  onStepNavigated(step) {
    if (this.router.navigate) {
      this.router.navigate([step.url], {
        relativeTo: this.route.parent,
        fragment: NavigationFragments.ScrollToFirstError,
      });

      //if navigating to the same route, ngAfterInit on Base Step doesn't run
      //issue on review page when navigating to review error from error list as it doesn't scroll to error
      if (this.router.routeReuseStrategy) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
      }
    }
  }

  get noPreviousErrors() {
    let noErrors = true;
    let hasErrors = false;
    const reviewErrorsName = `${REVIEW.code}Errors`;
    if (this.applicationErrors) {
      Object.keys(this.applicationErrors).forEach((item) => {
        //exclude reviewErrors from this check
        if (item !== reviewErrorsName) {
          const sectionError = this.applicationErrors[item];
          if (sectionError?.length > 0) {
            hasErrors = true;
          }
        }
      });
      if (hasErrors) {
        noErrors = false;
      }
      return noErrors;
    } else {
      return noErrors;
    }
  }

  saveReview() {
    const review = {
      ...this.reviewFormValue,
    } as FiReview;
    const reviewErrors = ValidationUtils.validateEntity(FiReview, review);
    this.applicationErrors.reviewErrors = reviewErrors;
    const applicationToSave = {
      id: this.applicationId.toString(),
      review: review,
      applicationErrors: this.applicationErrors,
    } as FscdIntakeApplication;

    return lastValueFrom(this.graphqlService.updateApplication(applicationToSave));
  }
}
