import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validators,
  AbstractControl,
  Validator,
} from '@angular/forms';
import { CompareValidator } from '../shared/validators/compare.validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'common-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EmailAddressComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EmailAddressComponent,
    },
  ],
})
export class EmailAddressComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  /*
  Email Address validation rules: Local-part@Domain
  Rules for Local-part:
  uppercase and lowercase Latin letters A to Z and a to z
  digits 0 to 9
  printable characters !#$%&'*+-/=?^_`{|}~
  dot ., provided that it is not the first or last character and provided also that it does not appear consecutively 
  */
  private validEmailPattern =
    "^[A-Za-z0-9!#$%&'*+-/=?^_`{|}~][A-Za-z0-9.!#$%&'*+-/=?^_`{|}~]*@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$";
  formGroup = this.fb.group({
    emailAddress: [null, [Validators.pattern(this.validEmailPattern)]],
  });
  @Input() label = 'Email address';
  @Input() confirmLabel = 'Confirmed email address';
  private _enableConfirm = false;
  @Input() set enableConfirm(value) {
    this._enableConfirm = value;
    if (value)
      this.formGroup.addControl(
        'confirmEmailAddress',
        this.fb.control(null, CompareValidator.shouldMatch('emailAddress', 'Email Address'))
      );
    this.setEmailAddressValidators();
  }
  private _isRequired = false;
  @Input() set isRequired(value) {
    this._isRequired = value;
    this.setEmailAddressValidators();
  }
  get isRequired() {
    return this._isRequired;
  }
  @Input() placeHolderText: string = null;
  private _isTouched = false;
  private touched;
  private changed;

  constructor(private fb: UntypedFormBuilder) {}
  private destroy$ = new Subject<void>();

  @Input() control: AbstractControl;
  @Input() hideOptionalLabel = false;
  @Input() helperText: string = null;
  @Input() helperMessage: string = null;
  @Input() dataCyPrefix = '';

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   * Same as phone-number-multiple
   */
  @Input() set isTouched(value) {
    if (value) {
      this.formGroup.markAllAsTouched();
      this._isTouched = true;
    } else {
      this.formGroup.markAsUntouched();
    }
  }

  ngOnInit(): void {
    this.formGroup.controls.emailAddress.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.changed && this.changed(value);
      this.markAsTouched();
    });
    if (this.control) this.control.markAsTouched = () => this.formGroup.markAllAsTouched();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: string) {
    this.formGroup.controls.emailAddress.setValue(value, { emitEvent: false });
  }
  registerOnChange(fn) {
    this.changed = fn;
  }
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });
  }
  validate() {
    return this.formGroup.valid ? null : { email: true };
  }
  onEmailBlur() {
    this.markAsTouched();
  }
  private markAsTouched() {
    if (!this._isTouched) {
      this.touched && this.touched();
      this._isTouched = true;
    }
  }
  private setEmailAddressValidators() {
    if (this._isRequired && this._enableConfirm)
      this.formGroup.controls.emailAddress.setValidators([
        Validators.required,
        CompareValidator.shouldMatch('confirmEmailAddress', this.confirmLabel),
        Validators.pattern(this.validEmailPattern),
      ]);
    else if (this._isRequired)
      this.formGroup.controls.emailAddress.setValidators([
        Validators.required,
        Validators.pattern(this.validEmailPattern),
      ]);
    else if (this._enableConfirm)
      this.formGroup.controls.emailAddress.setValidators([
        CompareValidator.shouldMatch('confirmEmailAddress', this.confirmLabel),
        Validators.pattern(this.validEmailPattern),
      ]);
    else this.formGroup.controls.emailAddress.setValidators([Validators.pattern(this.validEmailPattern)]);
    this.formGroup.controls.emailAddress.updateValueAndValidity({ emitEvent: false });
  }

  get emailPlaceHolder() {
    return this.placeHolderText || `${this.label.toLocaleLowerCase().replace(' ', '-')}@gov.ab.ca`;
  }
}
