<div [formGroup]="formGroup" class="client-intake-address" (click)="onAddressClick($event)">
  <div *ngIf="allowLookup">
    <common-address-lookup
      [dataCyPrefix]="dataCyPrefix"
      [suggestedAddresses]="suggestedAddresses"
      [(hideLookupResult)]="hideLookupResult"
      (lookupAddress)="onLookupAddress($event)"
      (selectAddress)="onSelectAddress($event)"
    >
    </common-address-lookup>
  </div>
  <div>
    <common-form-control
      controlName="streetAddress"
      label="Street address"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <ng-container *ngIf="!useAddressValidationService; else addressValidationInput">
        <input
          type="text"
          formControlName="streetAddress"
          [attr.data-cy]="dataCyPrefix + '-street'"
          autocomplete="street-address"
        />
      </ng-container>

      <ng-template #addressValidationInput>
        <input
          type="text"
          formControlName="streetAddress"
          [attr.data-cy]="dataCyPrefix + '-street'"
          autocomplete="street-address"
          [placeholder]="showStreetAddressPlaceholder ? 'Type to search or enter address manually' : ''"
          [matAutocomplete]="auto"
          (input)="onStreetAddressInputChange($event.target.value)"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onStreetAddressSelected($event.option.value)">
          <mat-option *ngFor="let address of suggestedAddresses" [value]="address">
            {{ address.Text }} {{ address.Description }}
          </mat-option>
        </mat-autocomplete>
      </ng-template>
    </common-form-control>
  </div>
  <div>
    <common-form-control label="Suite or unit #" controlName="suiteNumber" [isOptional]="!hideOptionalLabel">
      <input
        type="text"
        formControlName="suiteNumber"
        [attr.data-cy]="dataCyPrefix + '-suite'"
        autocomplete="address-line2"
      />
    </common-form-control>
  </div>
  <div class="postal-province-container" *ngIf="hideProvince">
    <common-form-control
      class="city"
      controlName="city"
      label="City/town"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <common-ab-city-autocomplete
        *ngIf="
          abMunicipalities && (!enableProvince || formGroup.value.province === defaultProvince);
          else textCityRequired
        "
        controlName="city"
        [formGroup]="formGroup"
        [cyTag]="dataCyPrefix + '-city'"
        [municipalities]="municipalities"
        [searchMethod]="municipalityMode"
      ></common-ab-city-autocomplete>
      <ng-template #textCityRequired>
        <input type="text" formControlName="city" [attr.data-cy]="dataCyPrefix + '-city'" autocomplete="city" />
      </ng-template>
    </common-form-control>
    <common-form-control
      class="postal-code"
      controlName="postalCode"
      label="Postal code"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <input
        [attr.data-cy]="dataCyPrefix + '-postalCode'"
        type="text"
        formControlName="postalCode"
        mask="S0S 0S0"
        placeholder="A0A 0A0"
        #postalCode
        iesPostalCodeValidator
        (input)="postalCode.value = postalCode.value.toUpperCase()"
        autocomplete="postal-code"
      />
    </common-form-control>
  </div>
  <div *ngIf="!hideProvince">
    <common-form-control controlName="city" label="City/town" [isOptional]="!hideOptionalLabel && !_isRequired">
      <common-ab-city-autocomplete
        *ngIf="abMunicipalities && (!enableProvince || formGroup.value.province === defaultProvince); else textCity"
        controlName="city"
        [formGroup]="formGroup"
        [cyTag]="dataCyPrefix + '-city'"
        [municipalities]="municipalities"
        [searchMethod]="municipalityMode"
      ></common-ab-city-autocomplete>
      <ng-template #textCity>
        <input type="text" formControlName="city" [attr.data-cy]="dataCyPrefix + '-city'" />
      </ng-template>
    </common-form-control>
  </div>
  <div class="postal-province-container" *ngIf="!hideProvince">
    <common-form-control
      class="province"
      label="Province/territory"
      controlName="province"
      [isOptional]="!hideOptionalLabel && !_isRequired && _enableProvince"
    >
      <ng-container *ngIf="_enableProvince; else readonlyProvince">
        <mat-select
          formControlName="province"
          [attr.data-cy]="dataCyPrefix + '-province'"
          role="combobox"
          aria-label="province"
          size="10"
        >
          <mat-option *ngFor="let province of provinces" [value]="province">{{ province }}</mat-option>
        </mat-select>
      </ng-container>
      <ng-template #readonlyProvince>
        <input
          type="text"
          [attr.data-cy]="dataCyPrefix + '-province'"
          formControlName="province"
          readonly
          matTooltip="{{ provinceTooltip }}"
          matTooltipPosition="above"
          matTooltipShowDelay="500"
        />
      </ng-template>
    </common-form-control>
    <common-form-control
      class="postal-code"
      controlName="postalCode"
      label="Postal code"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <input
        [attr.data-cy]="dataCyPrefix + '-postalCode'"
        type="text"
        formControlName="postalCode"
        mask="S0S 0S0"
        [placeholder]="postalCodeFormat === 'AB' ? 'T0T 0T0' : 'A0A 0A0'"
        #postalCode
        iesPostalCodeValidator
        [postalCodeFormat]="postalCodeFormat"
        (input)="postalCode.value = postalCode.value.toUpperCase()"
        autocomplete="postal-code"
      />
    </common-form-control>
  </div>
</div>
