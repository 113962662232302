import { Lookup } from './lookup.interface';
export const ReasonsForLeaving: { [name: string]: Lookup } = {
  contractEnded: { code: 'contractEnded', displayValue: 'Contract ended' },
  fired: { code: 'fired', displayValue: 'Fired' },
  laidOff: {
    code: 'laidOff',
    displayValue: 'Laid off (company closing or downsizing)',
  },
  legalReasons: { code: 'legalReasons', displayValue: 'Legal reasons' },
  medicalCondition: { code: 'medicalCondition', displayValue: 'Medical condition' },
  newJob: { code: 'newJob', displayValue: 'New job' },
  pursueEducation: { code: 'pursueEducation', displayValue: 'Pursue education or training' },
  quit: { code: 'quit', displayValue: 'Quit' },
  retire: { code: 'retire', displayValue: 'Retire' },
  seasonal: { code: 'seasonal', displayValue: 'Seasonal/temporary/casual' },
  terminated: { code: 'terminated', displayValue: 'Terminated during probationary period' },
  transportation: {
    code: 'transportation',
    displayValue: 'Transportation to and from work',
  },
  workplacePerformance: {
    code: 'workplacePerformance',
    displayValue: 'Workplace performance/relationship issues',
  },
  workSchedule: { code: 'workSchedule', displayValue: 'Work schedule/location' },
  other: { code: 'other', displayValue: 'Other' },
};
