import { Scale } from './assessment-scales';
import { Lookup } from '../lookup.interface';

export interface Question {
  question: string;
  followupQuestions?: Question[];
}

export interface SkillsLookup extends Lookup {
  scale?: Scale;
  options?: { [name: string]: Lookup };
  textField?: boolean;
  helperText?: string;
  footerText?: string;
  responseDefinitions?: string[];
  sampleQuestions?: Question[];
  subSkills?: { [name: string]: SkillsLookup };
  usedBy?: string;
}
